import { ELP_LIST } from "src/constants/tokens";
import {
  useMultipleContractSingleData,
  useSingleCallResult,
} from "src/lib/hooks/multicall";
import { useWeb3Context } from "./web3Context";
import { Interface } from "@ethersproject/abi";
import { abi as ElpABI } from "../abis/ELP.json";
import { abi as ElpManagerAbi } from "../abis/ElpManager.json";
import {
  WHITE_GNS_TOKEN_ADDRESS,
  getNativeToken,
  getTokens,
} from "src/configs/Tokens";
import { useGetEdePrice } from "./getPrice_ede";
import { useEffect, useMemo, useState } from "react";
import { BN, toFromBN } from "src/utils/bn";
import {
  useEDEContract,
  useEDEStakingContract,
  useFeeRouterContract,
} from "./useContract";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { compareAddress } from "src/utils/address";
import axios from "axios";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import {
  useQeuryEUSDCollateral,
  useQueryCircLocked,
  useQueryInfoHelper,
} from "./useQueryEarn";
import { abi as RewardRouterABI } from "src/abis/RewardRouter.json";
import { AddressZero } from "src/constants/address";
import { abi as MultiRewardStakingABI } from "src/abis/MultiRewardStaking.json";
import { addresses as ADDRESS } from "src/configs/constants";
const ELP_INTERFACE = new Interface(ElpABI);
const ELP_MANAGER_INTERFACE = new Interface(ElpManagerAbi);
const REWOR_INTERFACE = new Interface(RewardRouterABI);
const STAKE_POOL_INTERFACE = new Interface(MultiRewardStakingABI);
const getFeeAmount = async () => {
  const dalyFeeUrl_bsc = "https://data.ede.finance/api/ede/dalyFee";
  const baseUrl = dalyFeeUrl_bsc;
  return axios.get(baseUrl);
};

export const useBalance_EDE_BNB_LP = () => {
  const { chainID } = useWeb3Context();
  const EDE_BNB_LP_addr = ADDRESS[chainID].EDE_BNB_LP;
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    EDE_BNB_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_EDE = () => {
  const { chainID, address } = useWeb3Context();
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_EDE_aEDE_LP = () => {
  const { chainID } = useWeb3Context();
  const EDE_aEDE_LP_addr = ADDRESS[chainID].EDE_aEDE_LP;
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    EDE_aEDE_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_EDE_staking = () => {
  const { chainID } = useWeb3Context();
  const EDEStaking_addr = ADDRESS[chainID].EDEStaking;
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    EDEStaking_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const getRoundId = () => {
  return Math.floor(new Date().getTime() / 1000 / 86400) - 1;
};

export const useDataList = () => {
  const tokensArr = useQueryListData();
  const edePrice = useGetEdePrice();
  const balance_EDE_BNB_LP = useBalance_EDE_BNB_LP();
  const balance_EDE_aEDE_LP = useBalance_EDE_aEDE_LP();
  const balance_EDE_staking = useBalance_EDE_staking();
  const {
    totalSupply_ede,
    circulatingSupply,
    CirculatingLp,
    CirculatingHolders,
    CirculatingStaked,
  } = useQueryCircLocked();
  const rank = useQueryInfoHelper();
  const collateralList = useQeuryEUSDCollateral();

  return useMemo(() => {
    if (
      !tokensArr ||
      !totalSupply_ede ||
      !circulatingSupply ||
      !rank ||
      !edePrice
    )
      return;
    return {
      ...tokensArr,
      eusdPrice: 1,
      edePrice: edePrice.toNumber(),
      collateralList: collateralList ?? [],
      totalSupply_ede: totalSupply_ede.toNumber(),
      totalStaked_ede: 0,
      circulatingSupply: circulatingSupply.toNumber(),
      balance_ede_EDE_BUSD: 0,
      balance_EDE_BNB_LP: balance_EDE_BNB_LP
        ? balance_EDE_BNB_LP.toNumber()
        : 0,
      balance_EDE_aEDE_LP: balance_EDE_aEDE_LP
        ? balance_EDE_aEDE_LP.toNumber()
        : 0,
      balance_EDE_staking: balance_EDE_staking
        ? balance_EDE_staking.toNumber()
        : 0,
      rank,
      circLocked: 0,
      avgLockTime: 0,
      CirculatingLp,
      CirculatingHolders,
      CirculatingStaked,
    };
  }, [
    CirculatingHolders,
    CirculatingLp,
    CirculatingStaked,
    balance_EDE_BNB_LP,
    balance_EDE_aEDE_LP,
    balance_EDE_staking,
    circulatingSupply,
    collateralList,
    edePrice,
    rank,
    tokensArr,
    totalSupply_ede,
  ]);
};

export const useQueryListData = () => {
  const { chainID, address } = useWeb3Context();
  const elpList = ELP_LIST[chainID];
  const tokens = getTokens(chainID);
  const edeStakeContract = useEDEStakingContract();

  const edePrice = useGetEdePrice();
  const elp_addr = elpList.map((elp) => elp.Elp_address);
  const elp_manager_addr = elpList.map((elp) => elp.ElpManager_address);
  const rewordRouter_addr = elpList.map(() => ADDRESS[chainID].RewardRouter);
  const elp_rewardTracker_addr = elpList.map(
    (elp) => elp.rewardTracker_address
  );

  const EDE_ADDRESS = ADDRESS[chainID]?.EDE;
  const EDET_ADDRESS = ADDRESS[chainID]?.EDET;
  const VaultResv = ADDRESS[chainID]?.VaultResv;

  const NATIVE_TOKEN = getNativeToken(chainID);
  const feeRouterContract = useFeeRouterContract();

  const { result: elpEUSDFees } = useSingleCallResult(
    feeRouterContract,
    "feeRecords",
    [VaultResv, getRoundId()]
  );

  const poolInfos = useMultipleContractSingleData(
    elp_manager_addr,
    ELP_MANAGER_INTERFACE,
    "getPoolInfo"
  );
  const tokenLists = useMultipleContractSingleData(
    elp_manager_addr,
    ELP_MANAGER_INTERFACE,
    "getPoolTokenList"
  );
  const elpBalanceAll = useMultipleContractSingleData(
    elp_addr,
    ELP_INTERFACE,
    "balanceOf",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );
  const elpBalanceStakedAll = useMultipleContractSingleData(
    elp_rewardTracker_addr,
    STAKE_POOL_INTERFACE,
    "balanceOf",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );

  const tokenPerBlocks = useMultipleContractSingleData(
    elp_rewardTracker_addr,
    STAKE_POOL_INTERFACE,
    "getRewardTokens"
  );

  const poolRewords = useMultipleContractSingleData(
    elp_rewardTracker_addr,
    STAKE_POOL_INTERFACE,
    "claimable",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );

  const totalDepositSupplys = useMultipleContractSingleData(
    elp_rewardTracker_addr,
    STAKE_POOL_INTERFACE,
    "totalDepositSupply"
  );
  const { managerAddr, tokenAddr } = useMemo(() => {
    const managerAddr: string[] = [];
    const tokenAddr: any = [];
    elp_manager_addr.map((manager, index) => {
      const { result } = tokenLists[index];
      if (!result) return;
      const data = result[0];
      data.map((x: any) => {
        managerAddr.push(manager);
        tokenAddr.push([[x]]);
      });
    });
    return {
      managerAddr,
      tokenAddr,
    };
  }, [elp_manager_addr, tokenLists]);
  const getPoolTokenInfos = useMultipleContractMultipleData(
    !!managerAddr.length ? managerAddr : [undefined],
    ELP_MANAGER_INTERFACE,
    "getPoolTokenInfo",
    !!tokenAddr.length ? tokenAddr : undefined
  );

  const chainInfoArr: any = useMemo(() => {
    return managerAddr.reduce((pre: any, curr, index) => {
      if (!getPoolTokenInfos[index][0]) return;
      const { result } = getPoolTokenInfos[index][0];
      if (!result) return;
      const tokenInfo = result[0];
      const addr = tokenAddr[index][0][0];
      const currTokenArr = tokens.filter((token: any) =>
        compareAddress(addr, token.address)
      );

      const currToken = currTokenArr[0];
      const tokenWeight = toFromBN(tokenInfo[0], 6).toNumber();
      const utilization = toFromBN(tokenInfo[1], 6).toNumber();
      const price = toFromBN(tokenInfo[3], 30).toNumber();

      let poolNum = BN(0);
      let poolAmount = 0;
      if (tokenInfo[2].gt(0)) {
        poolNum = toFromBN(tokenInfo[2], currToken.decimals);
        poolAmount = poolNum.times(price).toNumber();
      } else {
        poolAmount = 0;
      }
      const data = {
        tokenWeight,
        price,
        utilization,
        poolNum: poolNum.toNumber(),
        poolAmount,
        ...currToken,
      };
      const cur = curr.toLocaleLowerCase();
      if (pre[cur]) {
        pre[cur].totalAmount += poolAmount;
        pre[cur].chainInfoArr.push(data);
      } else {
        pre[cur] = { chainInfoArr: [data], totalAmount: poolAmount };
      }
      return pre;
    }, {});
  }, [getPoolTokenInfos, managerAddr, tokenAddr, tokens]);
  const poolData = useMemo(() => {
    return elpList.reduce((pre: any, curr, index) => {
      if (!poolInfos[index]) return;
      const {
        result: poolInfo,
        loading: poolInfoLoading,
        valid: poolInfoValid,
      } = poolInfos[index];
      if (!tokenLists[index]) return;

      const {
        result: tokenList,
        loading: tokenListLoading,
        valid: tokenListValid,
      } = tokenLists[index];

      if (poolInfoLoading || tokenListLoading) return;

      if (!poolInfoValid || !tokenListValid) return;
      if (!poolInfo || !tokenList) return;
      const pool = poolInfo[0];
      const totalSupply = toFromBN(pool[2]).toFixed();
      const marketCap = toFromBN(pool[0], 30).toFixed();
      // 测试环境 没有价格默认显示为1
      let LP_Price: any = 1;
      if (pool[0].gt(0)) {
        LP_Price = BN(pool[0].div(pool[2])?.toString())
          .div(BN(10).pow(12))
          .toFixed();
      }
      const usdxSupply = pool[3];
      // const usdxSupply = bigNumberify("100000000000000000000000000000");
      const token = tokenList[0];
      const cur = curr.ElpManager_address.toLocaleLowerCase();
      pre[cur] = {
        totalSupply,
        marketCap,
        price: LP_Price,
        usdxSupply,
        tokenList: token,
      };
      return pre;
    }, {});
  }, [elpList, poolInfos, tokenLists]);

  const elpBalanceAndSake = useMemo(() => {
    return elp_addr.reduce<{
      [key: string]: {
        elpBalance: string;
        elpBalanceStaked: string;
      };
    }>((pre: any, curr, index) => {
      const elp = curr.toLocaleLowerCase();
      const { result } = elpBalanceAll[index];
      const { result: stake } = elpBalanceStakedAll[index];
      if (!result || !stake) return;
      pre[elp] = {
        elpBalance: toFromBN(result[0]).toFixed(),
        elpBalanceStaked: toFromBN(stake[0]).toFixed(),
      };
      return pre;
    }, {});
  }, [elpBalanceAll, elpBalanceStakedAll, elp_addr]);

  const totalDepositLoding = totalDepositSupplys.some(({ loading }) => loading);
  const tokenPerBlocksLoding = tokenPerBlocks.some(({ loading }) => loading);
  const AllPoolsInfo = useMemo(() => {
    if (totalDepositLoding || tokenPerBlocksLoding) return;
    return elp_addr.reduce<{
      [key: string]: {
        totalStaked: string;
        tokenPreRewards: {
          edetIndex: number;
          edeIndex: number;
          edetTokenPer: string;
          edeTokenPrer: string;
        };
        tokenRewards: {
          edetReward: string;
          edeReward: string;
        };
      };
    }>((pre: any, curr, index) => {
      const { result: totalDepositSupply } = totalDepositSupplys[index];
      const { result: tokenPerBlock } = tokenPerBlocks[index];
      const { result: tokenRewards } = poolRewords[index];

      if (!totalDepositSupply || !tokenPerBlock || !tokenRewards) return pre;

      const EDE_index = tokenPerBlock[0].findIndex((elp: string) =>
        compareAddress(elp, EDE_ADDRESS)
      );
      const EDEt_index = tokenPerBlock[0].findIndex((elp: string) =>
        compareAddress(elp, EDET_ADDRESS)
      );

      if (EDE_index != -1 && EDEt_index != -1) {
        const cur = curr.toLocaleLowerCase();
        pre[cur] = {
          totalStaked: toFromBN(totalDepositSupply[0]).toFixed(),
          tokenPreRewards: {
            edetIndex: EDEt_index,
            edeIndex: EDE_index,
            edetTokenPer: toFromBN(tokenPerBlock[1][EDEt_index]).toFixed(),
            edeTokenPrer: toFromBN(tokenPerBlock[1][EDE_index]).toFixed(),
          },
          tokenRewards: {
            edetReward: toFromBN(tokenRewards[0][EDEt_index]).toFixed(),
            edeReward: toFromBN(tokenRewards[0][EDE_index]).toFixed(),
          },
        };
      }
      return pre;
    }, {});
  }, [
    EDET_ADDRESS,
    EDE_ADDRESS,
    elp_addr,
    poolRewords,
    tokenPerBlocks,
    tokenPerBlocksLoding,
    totalDepositLoding,
    totalDepositSupplys,
  ]);

  const ALLData = useMemo(() => {
    if (
      !chainInfoArr ||
      !poolData ||
      !AllPoolsInfo ||
      !edePrice ||
      !elpBalanceAndSake ||
      !elpEUSDFees
    )
      return;
    return elpList.reduce(
      (pre: any, curr) => {
        const elpManager = curr.ElpManager_address.toLocaleLowerCase();
        const elp = curr.Elp_address.toLocaleLowerCase();
        const infoArr = chainInfoArr[elpManager];
        const poolDatas = poolData[elpManager];
        const { totalStaked, tokenPreRewards, tokenRewards } =
          AllPoolsInfo[elp] ?? {};
        const { elpBalance, elpBalanceStaked } = elpBalanceAndSake[elp];
        const elpEUSDFee = toFromBN(elpEUSDFees[0]);

        const tokenIfo = infoArr.chainInfoArr.map((item: any) => {
          const poolAmount = item.poolAmount;
          const poolsum = infoArr.totalAmount;
          const tokenAddr = item.address.toLocaleLowerCase();
          if (item.isWrapped) {
            const zero = AddressZero.toLocaleLowerCase();
            if (!pre.tokensMap[zero]) {
              pre.allToken.push({
                elp_type: curr.name,
                ...NATIVE_TOKEN,
              });
              pre.tokensMap[zero] = {
                elp_type: curr.name,
                ...NATIVE_TOKEN,
              };
            }
          }
          if (curr.isGNS) {
            const gnsTokens = WHITE_GNS_TOKEN_ADDRESS(chainID);
            gnsTokens.map((xitem) => {
              if (!xitem.address) return;
              const GNStokenAddr = xitem.address.toLocaleLowerCase();
              if (!pre.tokensMap[GNStokenAddr]) {
                pre.allToken.push({
                  elp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                });
                pre.tokensMap[GNStokenAddr] = {
                  elp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                };
              }
            });
          }

          if (!pre.tokensMap[tokenAddr]) {
            pre.tokensMap[tokenAddr] = {
              elp_type: curr.name,
              ...item,
            };
            pre.allToken.push({
              elp_type: curr.name,
              ...item,
            });
          }

          const tokenWeight2 = BN(poolAmount).div(poolsum).toNumber();
          return {
            ...item,
            tokenWeight2,
          };
        });

        const apr_EDET_elp = BN(tokenPreRewards?.edetTokenPer || 0)
          .times(365)
          .div(totalStaked)
          .times(edePrice);
        const apr_aEDE_elp = BN(tokenPreRewards?.edeTokenPrer || 0)
          .times(365)
          .div(totalStaked)
          .times(edePrice);
        const apr_eusd_elp = elpEUSDFee
          .times(365)
          .div(totalStaked)
          .times(edePrice);
        const apr = apr_aEDE_elp.plus(apr_eusd_elp).toNumber();
        const data = {
          ...curr,
          ...poolDatas,
          chainInfoArr: tokenIfo,
          address: curr.Elp_address,
          tokenPreRewards,
          totalStaked_elp: totalStaked,
          apr_aEDE_elp: apr_aEDE_elp,
          apr_EDET_elp,
          tokenRewards,
          apr_eusd_elp: apr_eusd_elp,
          apr,

          elpBalance,
          elpBalanceStaked,
        };
        pre.tokensArr.push(data);
        pre.ELPMAP[curr.name] = data;
        return pre;
      },
      { tokensMap: {}, allToken: [], tokensArr: [], ELPMAP: {} }
    );
  }, [
    chainInfoArr,
    poolData,
    AllPoolsInfo,
    edePrice,
    elpBalanceAndSake,
    elpEUSDFees,
    elpList,
    NATIVE_TOKEN,
    chainID,
  ]);

  return ALLData;
};

export const usePromise = () => {
  const [state, setState] = useState<undefined | any>();

  useEffect(() => {
    getFeeAmount().then((res) => {
      setState(res.data || {});
    });
  }, []);

  return {
    data: state,
  };
};
