import { createReducer } from "@reduxjs/toolkit";
import { priceList, priceList_gns, edetRewards } from "./actions";

type HttpState = {
  price?: string;
  price_gns?: any;
  elp1Edet?: {
    address: string;
    hash: string;
    userReward: string;
    roundId: number;
  };
};

const initialState: HttpState = {
  price: undefined,
  price_gns: undefined,
  elp1Edet: undefined,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(priceList, (state, { payload: data }) => {
      state.price = data;
    })
    .addCase(priceList_gns, (state, { payload: data }) => {
      state.price_gns = data;
    })
    .addCase(edetRewards, (state, { payload: data }) => {
      state.elp1Edet = data;
    })
);
