import { useMemo } from "react";
import { Interface } from "@ethersproject/abi";
import { useWeb3Context } from "./web3Context";
import { useOrderBookReaderContract } from "./useContract";
import {
  DECREASE,
  INCREASE,
  PLACEHOLDER_ACCOUNT,
  SWAP,
} from "src/helpers/Helpers";
import { ELP_LIST } from "src/constants/tokens";
import { abi as OrderBookABI } from "../abis/OrderBook.json";
import { abi as OrderBookReaderABI } from "../abis/OrderBookReader.json";
import { useMultipleContractSingleData } from "src/lib/hooks/multicall";

const serverIndexes = { swap: [], increase: [], decrease: [] };

const getRange = (to: number, from?: number | undefined) => {
  const LIMIT = 10;
  const _indexes = [];
  from = from || Math.max(to - LIMIT, 0);
  for (let i = to - 1; i >= from; i--) {
    _indexes.push(i);
  }
  return _indexes;
};

const getIndexes = (knownIndexes: any[], lastIndex: number) => {
  if (knownIndexes.length === 0) {
    return getRange(lastIndex);
  }
  return [
    ...knownIndexes,
    ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort(
      (a, b) => b - a
    ),
  ];
};

const ORDERBOOK_INTERFACE = new Interface(OrderBookABI);
const ORDERBOOK_READER = new Interface(OrderBookReaderABI);

export const useAccountOrderList = () => {
  const { chainID, address } = useWeb3Context();
  const orderBookReaderContract = useOrderBookReaderContract();
  const elpList = ELP_LIST[chainID];
  const orderBook_address_arr = elpList.map((elp) => elp.orderBook_address);
  orderBookReaderContract?.getSwapOrders;
  const { orderBookAddressArr, paramArr } = useMemo(() => {
    const orderBookAddressArr: string[] = [];
    const paramArr: any = [];

    orderBook_address_arr.forEach((x) => {
      orderBookAddressArr.push(x);
      paramArr.push([[address ? address : PLACEHOLDER_ACCOUNT]]);
    });
    return {
      orderBookAddressArr,
      paramArr,
    };
  }, [address, orderBook_address_arr]);

  const AllOrders = useMultipleContractSingleData(
    orderBook_address_arr,
    ORDERBOOK_INTERFACE,
    "getPendingOrders",
    [address ? address : PLACEHOLDER_ACCOUNT],
    { gasRequired: 8000000 }
  );

  const data = useMemo(() => {
    return orderBookAddressArr.reduce((pre: any, curr, index) => {
      if (AllOrders.length === 0) return pre;
      const { result } = AllOrders[index];

      if (!result) return [];

      const IncreaseOrder: any = result[0];
      const DecreaseOrder: any = result[1];
      const SwapOrder: any = result[2];

      if (!IncreaseOrder || !DecreaseOrder || !SwapOrder) return pre;

      const swapOrdersData = SwapOrder.map((order: any, index: number) => {
        return {
          contract: curr,
          ...order,
          type: SWAP,
          index: index,
        };
      });
      const increaseOrdersData = IncreaseOrder.map(
        (order: any, index: number) => {
          return {
            contract: curr,
            ...order,
            type: INCREASE,
            index: index,
          };
        }
      );
      const decreaseOrdersData = DecreaseOrder.map(
        (order: any, index: number) => {
          return {
            contract: curr,
            ...order,
            type: DECREASE,
            index: index,
          };
        }
      );

      const data = [
        ...swapOrdersData,
        ...increaseOrdersData,
        ...decreaseOrdersData,
      ];
      pre.push(...data);

      return pre;
    }, []);
  }, [orderBookAddressArr, AllOrders]);
  return data;
};
