import { SupportedChainId } from "./chainENV/chains.base";

export const VaultUtilsblacklist = [
  "0x276A0cb4973A1527Bc2E9cbD9b278eF8061A4F46",
  "0x3dfC44Ca476527Eb03D7f4B334ac958b201c8019",
  "0x03Fb73dFA117AA4B44C1A624d48FeD2076bf6382",
  "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
];

export const DOC_LINK = {
  documentation: "https://docs.edebase.finance/",
  discord: "https://discord.com/invite/g7GpVVxtxz",
  twitter: "https://twitter.com/ede_finance",
  telegram: "https://t.me/ede_finance",
  medium: "https://medium.com/@ede_finance",
  github: "https://github.com/El-Dorado-Exchange",
  more: "https://docs.edebase.finance/ede-base/elp-liquidity-provision",
  stats: "https://stats.edebase.finance/",
};

export const PRICE_CHART_URL: { [key: number]: string } = {
  [SupportedChainId.BSCMAINNET]: "https://data.ede.finance/bsc/edekline",
  [SupportedChainId.ARBITRUM]: "https://data.ede.finance/arb/edekline",
  [SupportedChainId.ARB_TEST]: "https://data.ede.finance/arb/edekline",
  [SupportedChainId.BSCTEST]: "https://data.ede.finance/bsc/edekline",
};
export const AddLiquidityUrl: { [key: number]: string } = {
  [SupportedChainId.BSCMAINNET]:
    "https://pancakeswap.finance/v2/add/BNB/0x4136129Ac3aC90cF9817548b24D35E73E9457e05",
  [SupportedChainId.BSCTEST]:
    "https://pancakeswap.finance/v2/add/BNB/0x4136129Ac3aC90cF9817548b24D35E73E9457e05",
  [SupportedChainId.ARBITRUM]:
    " https://app.camelot.exchange/liquidity/?token1=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&token2=0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
  [SupportedChainId.ARB_TEST]:
    " https://app.camelot.exchange/liquidity/?token1=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&token2=0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
  [SupportedChainId.BASE_MAINNET]:
    "https://baseswap.fi/add/ETH/0x0A074378461FB7ed3300eA638c6Cc38246db4434",
  [SupportedChainId.BASE_TEST]:
    "https://baseswap.fi/add/ETH/0x0A074378461FB7ed3300eA638c6Cc38246db4434",
};
export const RemoveLiquidityUrl: { [key: number]: string } = {
  [SupportedChainId.BSCMAINNET]:
    "https://pancakeswap.finance/v2/remove/0x4136129Ac3aC90cF9817548b24D35E73E9457e05/BNB",
  [SupportedChainId.BSCTEST]:
    "https://pancakeswap.finance/v2/remove/0x4136129Ac3aC90cF9817548b24D35E73E9457e05/BNB",
  [SupportedChainId.ARBITRUM]: " https://app.camelot.exchange/positions/",
  [SupportedChainId.ARB_TEST]: " https://app.camelot.exchange/positions/",
  [SupportedChainId.BASE_MAINNET]:
    "https://baseswap.fi/remove/ETH/0x0A074378461FB7ed3300eA638c6Cc38246db4434",
  [SupportedChainId.BASE_TEST]:
    "https://baseswap.fi/remove/ETH/0x0A074378461FB7ed3300eA638c6Cc38246db4434",
};

export const DEFAULT_URL = "https://api.ede.finance";

export const PRICE_API: { [key: number]: string } = {
  [SupportedChainId.BSCMAINNET]:
    "https://xc-mainnet.pyth.network/api/latest_vaas",
  [SupportedChainId.BSCTEST]: "https://xc-testnet.pyth.network/api/latest_vaas",
  [SupportedChainId.ARB_TEST]:
    "https://hermes-beta.pyth.network/api/latest_vaas",
  [SupportedChainId.ARBITRUM]: "https://hermes.pyth.network/api/latest_vaas",
  [SupportedChainId.BASE_TEST]:
    "https://hermes-beta.pyth.network/api/latest_vaas",
  [SupportedChainId.BASE_MAINNET]:
    "https://hermes.pyth.network/api/latest_vaas",
};

// 网络请求轮询间隔
export const HTTP_POLL_DELAY = 3000;
// 网络轮询队列元素请求间隔
export const HTTP_QUEUEQUERY_DELAY = 500;

// 购买跳转链接

export const BUY_EDE_TOKEN_URL =
  "https://baseswap.fi/swap?outputCurrency=0x0a074378461fb7ed3300ea638c6cc38246db4434";
