import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ReactComponent as EdePriceIcon } from "src/assets/images/earn/EDE.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetEdePrice } from "src/hooks/getPrice_ede";
import { BUY_EDE_TOKEN_URL } from "src/constants/misc";
const MyBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;

  span {
    border-bottom: 1px dashed rgba(238, 242, 249, 0.5);
    padding-bottom: 4px;
  }
`;
const EdePrice = () => {
  const edePrice = useGetEdePrice();

  return (
    <MyBox
      className="font-weight-6  color4"
      onClick={() => window.open(BUY_EDE_TOKEN_URL)}
    >
      <EdePriceIcon height={32} width={32} />
      <span className=""> $ {formatAmount2(edePrice || 0, 2, true)}</span>
    </MyBox>
  );
};

export default EdePrice;
