import { useSingleCallResult } from "src/lib/hooks/multicall";
import { usePairContract } from "./useContract";
import { useMemo } from "react";
import { addresses as ADDRESS } from "src/configs/constants";
import { useWeb3Context } from "./web3Context";
import { BN, toFromBN } from "src/utils/bn";
import { compareAddress } from "src/utils/address";
import { PAIR_DETAILS } from "src/constants/tokens";
import { useGetEdePrice } from "./getPrice_ede";

export const useGetLpPrice = (lpaddr?: string) => {
  const { chainID } = useWeb3Context();
  const pairContract = usePairContract(lpaddr);
  const EDEADDr = ADDRESS[chainID]?.EDE;
  const pairs = PAIR_DETAILS[chainID];
  const edePrice = useGetEdePrice();
  const { result: getReserves } = useSingleCallResult(
    pairContract,
    "getReserves"
  );
  const { result: lpTotalSupply } = useSingleCallResult(
    pairContract,
    "totalSupply"
  );
  return useMemo(() => {
    if (!getReserves || !lpTotalSupply || !lpaddr || !edePrice) return;
    const getReserve = getReserves;
    const pair = pairs[lpaddr.toLocaleLowerCase()];
    if (!pair) return;
    const token0Amount = toFromBN(getReserve._reserve0, pair.token0.decimals);
    const token1Amount = toFromBN(getReserve._reserve1, pair.token1.decimals);
    const lpTotal = toFromBN(lpTotalSupply[0]);
    let lpRate = BN(0);
    if (compareAddress(pair.token0.address, EDEADDr)) {
      lpRate = token0Amount.div(lpTotal);
    } else {
      lpRate = token1Amount.div(lpTotal);
    }
    const lpPrice = lpRate.times(edePrice).times(2);
    return lpPrice;
  }, [EDEADDr, edePrice, getReserves, lpTotalSupply, lpaddr, pairs]);
};
