import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { InjectedConnector } from "@web3-react/injected-connector"
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from "@web3-react/walletconnect-connector"
import { format as formatDateFn } from "date-fns"
import { ethers, logger } from "ethers"
import _ from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { useLocalStorage } from "react-use"
import useSWR from "swr"
import Token from "../abis/Token.json"
import { getContractAddress } from "../Addresses"

import OrderBook from "../abis/OrderBook.json"
import OrderBookReader from "../abis/OrderBookReader.json"

import { useWeb3Context } from "src/hooks"
import { getWhitelistedTokens, isValidToken } from "../configs/Tokens"

import { serializeError } from "eth-rpc-errors"
import { toastError, toastSuccess } from "./toastHelpers"
import axios from "axios";
import { getOrderBookAddress } from "./elpAddress"
import BigNumber from "bignumber.js"

const { AddressZero } = ethers.constants;

export const UI_VERSION = "1.3";

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;

export const MAINNET = 56;
export const TESTNET = 97;
export const AVALANCHE = 43114;
export const ARBITRUM = 42161;
export const ARBITRUM_TEST = 421613;
// TODO take it from web3
export const DEFAULT_CHAIN_ID = 56;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const MIN_PROFIT_TIME = 0;

const SELECTED_NETWORK_LOCAL_STORAGE_KEY = "SELECTED_NETWORK_BASE";

const CHAIN_NAMES_MAP = {
  [MAINNET]: "BSC",
  [TESTNET]: "BSC Testnet",
  [ARBITRUM]: "Arbitrum",
  [ARBITRUM_TEST]: "Arbitrum Testnet",
  [AVALANCHE]: "Avalanche",
};

const GAS_PRICE_ADJUSTMENT_MAP = {
  [MAINNET]: "0",
  [TESTNET]: "0",
  [ARBITRUM]: "0",
  [ARBITRUM_TEST]: "0",
  [AVALANCHE]: "3000000000", // 3 gwei
};

const MAX_GAS_PRICE_MAP = {
  [AVALANCHE]: "200000000000", // 200 gwei
};

const alchemyWhitelistedDomains = ["gmx.io", "app.gmx.io"];

export function getDefaultBscRpcUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/hxBqIr-vfpJ105JPYLei_ibbJLe66k46";
  }
  return "https://data-seed-prebsc-1-s1.binance.org:8545"; // "https://rpc.ankr.com/bsc_testnet_chapel"; //
}

export function getDefaultArbitrumRpcUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/hxBqIr-vfpJ105JPYLei_ibbJLe66k46";
  }
  return "https://data-seed-prebsc-1-s1.binance.org:8545"; // "https://arb1.arbitrum.io/rpc";
}

export function getAlchemyHttpUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

const ARBITRUM_RPC_PROVIDERS = [getDefaultArbitrumRpcUrl()];
const AVALANCHE_RPC_PROVIDERS = ["https://api.avax.network/ext/bc/C/rpc"];
export const WALLET_CONNECT_LOCALSTORAGE_KEY = "walletconnect";
export const WALLET_LINK_LOCALSTORAGE_PREFIX = "-walletlink";
export const SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY = "eagerconnect";
export const CURRENT_PROVIDER_LOCALSTORAGE_KEY = "currentprovider";

export function getChainName(chainId) {
  return CHAIN_NAMES_MAP[chainId];
}
export const USDX_ADDRESS = getContractAddress(CHAIN_ID, "USDX");
export const MAX_LEVERAGE = 100 * 10000;

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 250;
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000;
export const SECONDS_PER_YEAR = 31536000;
export const USDX_DECIMALS = 18;
export const USD_DECIMALS = 30;
export const BASIS_POINTS_DIVISOR = 10000;
export const DEPOSIT_FEE = 30;
export const DUST_BNB = "5000000000000000";
export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
export const ELP_DECIMALS = 18;
export const EUSD_DECIMALS = 18;
export const DEFAULT_MAX_USDX_AMOUNT = expandDecimals(200 * 1000000 * 1000000, 18);

export const TAX_BASIS_POINTS = 80;
export const TAX_BASIS_POINTS2 = 60;
export const STABLE_TAX_BASIS_POINTS = 80;
export const MINT_BURN_FEE_BASIS_POINTS = 25;
export const SWAP_FEE_BASIS_POINTS = 20;
export const SWAP_FEE_BASIS_POINTS2 = 40;
export const STABLE_SWAP_FEE_BASIS_POINTS = 20;
export const MARGIN_FEE_BASIS_POINTS = 10;

export const LIQUIDATION_FEE = expandDecimals(5, USD_DECIMALS);

export const ELP_COOLDOWN_DURATION = 15 * 60;
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27); // 0.993
export const FUNDING_RATE_PRECISION = 1000000;

export const SWAP = "Swap";
export const INCREASE = "Increase";
export const DECREASE = "Decrease";
export const LONG = "Long";
export const SHORT = "Short";

export const MARKET = "Market";
export const LIMIT = "Limit";
export const STOP = "Stop";
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP];
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT];
export const SWAP_OPTIONS = [LONG, SHORT, SWAP];
export const DEFAULT_SLIPPAGE_AMOUNT = 30;
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100;

export const SLIPPAGE_BPS_KEY = "Exchange-swap-slippage-basis-points-v3";
export const CLOSE_POSITION_RECEIVE_TOKEN_KEY = "Close-position-receive-token";
export const IS_PNL_IN_LEVERAGE_KEY = "Exchange-swap-is-pnl-in-leverage";
export const SHOW_PNL_AFTER_FEES_KEY = "Exchange-swap-show-pnl-after-fees";
export const DISABLE_ORDER_VALIDATION_KEY = "disable-order-validation";
export const SHOULD_SHOW_POSITION_LINES_KEY = "Exchange-swap-should-show-position-lines";
export const REFERRAL_CODE_KEY = "ELP-referralCode";
export const REFERRAL_CODE_QUERY_PARAM = "ref";
export const REFERRALS_SELECTED_TAB_KEY = "Referrals-selected-tab";
export const MAX_REFERRAL_CODE_LENGTH = 20;

export const TRIGGER_PREFIX_ABOVE = ">";
export const TRIGGER_PREFIX_BELOW = "<";

export const MIN_PROFIT_BIPS = 0;

export const ELPPOOLCOLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const HIGH_EXECUTION_FEES_MAP = {
  [MAINNET]: 3,
  [TESTNET]: 3,
  [ARBITRUM]: 3,
  [ARBITRUM_TEST]: 3,
  [AVALANCHE]: 3, // 3 USD
};

export const ICONLINKS = {
  97: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
  },
  56: {
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
  },
};

export const platformTokens = {
  97: {
    ELP: {
      name: "ELP",
      symbol: "ELP",
      decimals: 18,
      address: getContractAddress(97, "ELP_1p"), // address of fsELP token because user only holds fsELP
      imageUrl: "",
    },
  },
  56: {
    ELP: {
      name: "ELP",
      symbol: "ELP",
      decimals: 18,
      address: getContractAddress(56, "ELP"),
      imageUrl: "",
    },
  },
  421613: {
    ELP: {
      name: "ELP",
      symbol: "ELP",
      decimals: 18,
      address: getContractAddress(421613, "ELP"),
      imageUrl: "",
    },
  },
  42161: {
    ELP: {
      name: "ELP",
      symbol: "ELP",
      decimals: 18,
      address: getContractAddress(42161, "ELP"),
      imageUrl: "",
    },
  },
};

const supportedChainIds = [56, 97, 42161, 421613, 84531, 8453];
const injectedConnector = new InjectedConnector({
  supportedChainIds,
});

const getWalletConnectConnector = () => {
  const chainId = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;
  return new WalletConnectConnector({
    rpc: {
      56: "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
      42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
    },
    qrcode: true,
    chainId,
  });
};

export function isSupportedChain(chainId) {
  return supportedChainIds.includes(chainId);
}

export function deserialize(data) {
  for (const [key, value] of Object.entries(data)) {
    if (value._type === "BigNumber") {
      data[key] = bigNumberify(value.value);
    }
  }
  return data;
}

export function isHomeSite() {
  return process.env.REACT_APP_IS_HOME_SITE === "true";
}

export const helperToast = {
  waiting: content => {
    toast.dismiss();
    toast.info(content, {
      hideProgressBar: true,
      autoClose: false,
      toastId: "waiting"
    });
  },
  processing: content => {
    toast.dismiss("waiting");
    toast.info(content, {
      hideProgressBar: true,
      autoClose: false,
      toastId: "processing"
    });
  },
  transaction: content => {
    toast.dismiss("processing");
    toast.info(content, {
      hideProgressBar: true,
      autoClose: false,
      position: "top-center",
      toastId: "transaction"
    });
  },
  transactionUpdate: content => {
    toast.update("transaction", { render: content });
    // toast.dismiss("transaction");
    // toast.info(content, {
    //   hideProgressBar: true,
    //   autoClose: false,
    //   position: "top-center",
    //   toastId: "transaction2"
    // });
  },
  success: content => {
    toast.dismiss("processing");
    toast.success(content, {
      hideProgressBar: false,
      autoClose: 3000
    });
  },
  error: content => {
    toast.dismiss();
    toast.error(content, {
      hideProgressBar: false,
      autoClose: 3000
    });
  },
};

export function useLocalStorageByChainId(chainId, key, defaultValue) {
  const [internalValue, setInternalValue] = useLocalStorage(key, {});
  const setValue = useCallback(
    value => {
      setInternalValue(internalValue => {
        if (typeof value === "function") {
          value = value(internalValue[chainId] || defaultValue);
        }
        const newInternalValue = {
          ...internalValue,
          [chainId]: value,
        };
        return newInternalValue;
      });
    },
    [chainId, setInternalValue, defaultValue],
  );
  let value;
  if (chainId in internalValue) {
    value = internalValue[chainId];
  } else {
    value = defaultValue;
  }

  return [value, setValue];
}

export function useLocalStorageSerializeKey(key, value, opts) {
  key = JSON.stringify(key);
  return useLocalStorage(key, value, opts);
}

function getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd) {
  // Limit/stop orders are executed with price specified by user
  if (orderOption && orderOption !== MARKET && triggerPriceUsd) {
    return triggerPriceUsd;
  }

  // Market orders are executed with current market price
  if (!info) {
    return;
  }
  if (max && !info.maxPrice) {
    return;
  }
  if (!max && !info.minPrice) {
    return;
  }
  return max ? info.maxPrice : info.minPrice;
}

export function getLiquidationPriceFromDelta({ liquidationAmount, size, collateral, averagePrice, isLong }) {
  if (!size || size.eq(0)) {
    return;
  }

  if (liquidationAmount.gt(collateral)) {
    const liquidationDelta = liquidationAmount.sub(collateral);
    const priceDelta = liquidationDelta.mul(averagePrice).div(size);
    return isLong ? averagePrice.add(priceDelta) : averagePrice.sub(priceDelta);
  }

  const liquidationDelta = collateral.sub(liquidationAmount);
  const priceDelta = liquidationDelta.mul(averagePrice).div(size);

  return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta);
}

export const replaceNativeTokenAddress = (path, nativeTokenAddress) => {
  if (!path) {
    return;
  }

  let updatedPath = [];
  for (let i = 0;i < path.length;i++) {
    let address = path[i];
    if (address === AddressZero) {
      address = nativeTokenAddress;
    }
    updatedPath.push(address);
  }

  return updatedPath;
};

export function getMarginFee(sizeDelta) {
  if (!sizeDelta) {
    return bigNumberify(0);
  }
  const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
  return sizeDelta.sub(afterFeeUsd);
}

export function getServerBaseUrl(chainId) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }
  return "https://api.ede.finance";
}

export function getServerUrl(chainId, path) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function isTriggerRatioInverted(fromTokenInfo, toTokenInfo) {
  if (!toTokenInfo || !fromTokenInfo) return false;
  if (toTokenInfo.isStable || toTokenInfo.isUsdx) return true;
  if (toTokenInfo.maxPrice) return toTokenInfo.maxPrice < (fromTokenInfo.maxPrice);
  return false;
}

export function getExchangeRate(tokenAInfo, tokenBInfo, inverted) {
  if (!tokenAInfo || !tokenAInfo.minPrice || !tokenBInfo || !tokenBInfo.maxPrice) {
    return;
  }
  if (inverted) {
    return tokenAInfo.minPrice.mul(PRECISION).div(tokenBInfo.maxPrice || 1);
  }
  return tokenBInfo.maxPrice.mul(PRECISION).div(tokenAInfo.minPrice || 1);
}

export function getMostAbundantStableToken(chainId, infoTokens) {
  const whitelistedTokens = getWhitelistedTokens(chainId);
  let availableAmount;
  let stableToken = whitelistedTokens.find(t => t.isStable);
  for (let i = 0;i < whitelistedTokens.length;i++) {
    const info = getTokenInfo(infoTokens, whitelistedTokens[i].address);
    if (!info?.isStable || !info?.availableAmount) {
      continue;
    }

    const adjustedAvailableAmount = adjustForDecimals(info.availableAmount, info.decimals, USD_DECIMALS);
    if (!availableAmount || adjustedAvailableAmount.gt(availableAmount)) {
      availableAmount = adjustedAvailableAmount;
      stableToken = info;
    }
  }
  return stableToken;
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
  if (tokenB.isStable || tokenB.isUsdx) return true;
  if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true;
  return false;
}

export function getExchangeRateDisplay(rate, tokenA, tokenB, opts = {}) {
  if (!rate || !tokenA || !tokenB) return "...";
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    [tokenA, tokenB] = [tokenB, tokenA];
    rate = PRECISION.mul(PRECISION).div(rate);
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.tokenDecimals, true);
  if (opts.omitSymbols) {
    return rateValue;
  }
  return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`;
}

const adjustForDecimalsFactory = n => number => {
  if (n === 0) {
    return number;
  }
  if (n > 0) {
    return number.mul(expandDecimals(1, n));
  }
  return number.div(expandDecimals(1, -n));
};

export function adjustForDecimals(amount, divDecimals, mulDecimals) {
  return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals));
}

export function getTargetUsdxAmount(token, usdxSupply, totalTokenWeights) {
  if (!token || !token.weight || !usdxSupply) {
    return;
  }

  if (usdxSupply.eq(0)) {
    return bigNumberify(0);
  }

  return token.weight.mul(usdxSupply).div(totalTokenWeights);
}

export function getFeeBasisPoints(
  token, usdxDelta, feeBasisPoints, taxBasisPoints, increment, usdxSupply, totalTokenWeights,
) {
  if (!token || !token.usdxAmount || !usdxSupply || !totalTokenWeights) {
    return 0;
  }

  feeBasisPoints = bigNumberify(feeBasisPoints);
  taxBasisPoints = bigNumberify(taxBasisPoints);

  const initialAmount = token.usdxAmount;
  let nextAmount = initialAmount.add(usdxDelta);
  if (!increment) {
    nextAmount = usdxDelta.gt(initialAmount) ? bigNumberify(0) : initialAmount.sub(usdxDelta);
  }
  const targetAmount = getTargetUsdxAmount(token, usdxSupply, totalTokenWeights);
  if (!targetAmount || targetAmount.eq(0)) {
    return feeBasisPoints.toNumber();
  }

  const initialDiff = initialAmount.gt(targetAmount)
    ? initialAmount.sub(targetAmount)
    : targetAmount.sub(initialAmount);
  const nextDiff = nextAmount.gt(targetAmount) ? nextAmount.sub(targetAmount) : targetAmount.sub(nextAmount);

  if (nextDiff.lt(initialDiff)) {
    const rebateBps = taxBasisPoints.mul(initialDiff).div(targetAmount);
    return rebateBps.gt(feeBasisPoints) ? 0 : feeBasisPoints.sub(rebateBps).toNumber();
  }

  let averageDiff = initialDiff.add(nextDiff).div(2);
  if (averageDiff.gt(targetAmount)) {
    averageDiff = targetAmount;
  }
  const taxBps = taxBasisPoints.mul(averageDiff).div(targetAmount);
  return feeBasisPoints.add(taxBps).toNumber();
}

export function getNextFromAmount(
  elpName,
  chainId,
  toAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdxSupply,
  totalTokenWeights,
  forSwap,
) {
  const defaultValue = { amount: bigNumberify(0) };

  if (!toAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue;
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: toAmount };
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: toAmount };
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: toAmount };
  }

  let fromTokenMinPrice;
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
  }

  let toTokenMaxPrice;
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
  }

  if (!fromToken || !fromTokenMinPrice || !toToken || !toTokenMaxPrice) {
    return defaultValue;
  }

  const adjustDecimals = adjustForDecimalsFactory(fromToken.decimals - toToken.decimals);

  let fromAmountBasedOnRatio;
  if (ratio && !ratio.isZero()) {
    fromAmountBasedOnRatio = toAmount.mul(ratio).div(PRECISION);
  }

  const fromAmount =
    ratio && !ratio.isZero() ? fromAmountBasedOnRatio : toAmount.mul(toTokenMaxPrice).div(fromTokenMinPrice);

  let usdxAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
  usdxAmount = adjustForDecimals(usdxAmount, toToken.decimals, USDX_DECIMALS);
  const swapFeeBasisPointsVal = elpName == "ELP-1" ? SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS2;
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : swapFeeBasisPointsVal;
  const taxBasisPointsVal = elpName == "ELP-1" ? TAX_BASIS_POINTS : TAX_BASIS_POINTS2;
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : taxBasisPointsVal;
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdxAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdxSupply,
    totalTokenWeights,
  );
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdxAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdxSupply,
    totalTokenWeights,
  );
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;
  return {
    amount: adjustDecimals(fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)),
    feeBasisPoints,
  };
}

export function getNextToAmount(
  elpName,
  chainId,
  fromAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdxSupply,
  totalTokenWeights,
  forSwap,
) {
  const defaultValue = { amount: bigNumberify(0) };
  if (!fromAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue;
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: fromAmount };
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: fromAmount };
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: fromAmount };
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice;
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
  }

  let toTokenMaxPrice;
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
  }

  if (!fromTokenMinPrice || !toTokenMaxPrice) {
    return defaultValue;
  }

  const adjustDecimals = adjustForDecimalsFactory(toToken.decimals - fromToken.decimals);

  let toAmountBasedOnRatio = bigNumberify(0);
  if (ratio && !ratio.isZero()) {
    toAmountBasedOnRatio = fromAmount.mul(PRECISION).div(ratio);
  }

  if (toTokenAddress === USDX_ADDRESS) {
    const feeBasisPoints = getSwapFeeBasisPoints(fromToken.isStable, elpName);

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio;
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const toAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
    return {
      amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
      feeBasisPoints,
    };
  }

  if (fromTokenAddress === USDX_ADDRESS) {
    const redemptionValue = toToken.redemptionAmount
      .mul(toTokenPriceUsd || toTokenMaxPrice)
      .div(expandDecimals(1, toToken.decimals));

    if (redemptionValue.gt(THRESHOLD_REDEMPTION_VALUE)) {
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable, elpName);

      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));

      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const expectedAmount = fromAmount;

    const stableToken = getMostAbundantStableToken(chainId, infoTokens);
    if (!stableToken || stableToken.availableAmount.lt(expectedAmount)) {
      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable, elpName);
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const feeBasisPoints0 = getSwapFeeBasisPoints(true, elpName);
    const feeBasisPoints1 = getSwapFeeBasisPoints(false, elpName);

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
        .mul(BASIS_POINTS_DIVISOR - feeBasisPoints0 - feeBasisPoints1)
        .div(BASIS_POINTS_DIVISOR);
      return {
        amount: adjustDecimals(toAmount),
        path: [USDX_ADDRESS, stableToken.address, toToken.address],
        feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
      };
    }

    // get toAmount for USDX => stableToken
    let toAmount = fromAmount.mul(PRECISION).div(stableToken.maxPrice);
    // apply USDX => stableToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints0).div(BASIS_POINTS_DIVISOR);

    // get toAmount for stableToken => toToken
    toAmount = toAmount.mul(stableToken.minPrice).div(toTokenPriceUsd || toTokenMaxPrice);
    // apply stableToken => toToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints1).div(BASIS_POINTS_DIVISOR);

    return {
      amount: adjustDecimals(toAmount),
      path: [USDX_ADDRESS, stableToken.address, toToken.address],
      feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
    };
  }
  const toAmount =
    ratio && !ratio.isZero()
      ? toAmountBasedOnRatio
      : fromAmount.mul(fromTokenMinPrice).div(toTokenPriceUsd || toTokenMaxPrice);

  let usdxAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
  usdxAmount = adjustForDecimals(usdxAmount, fromToken.decimals, USDX_DECIMALS);
  const swapFeeBasisPointsVal = elpName == "ELP-1" ? SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS2;
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : swapFeeBasisPointsVal;
  const taxBasisPointsVal = elpName == "ELP-1" ? TAX_BASIS_POINTS : TAX_BASIS_POINTS2;
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : taxBasisPointsVal;
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdxAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdxSupply,
    totalTokenWeights,
  );
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdxAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdxSupply,
    totalTokenWeights,
  );
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;
  return {
    amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
    feeBasisPoints,
  };
}

export function getProfitPrice(closePrice, position) {
  let profitPrice;
  if (position && position.averagePrice && closePrice) {
    profitPrice = position.isLong
      ? position.averagePrice.mul(BASIS_POINTS_DIVISOR + MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
      : position.averagePrice.mul(BASIS_POINTS_DIVISOR - MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR);
  }
  return profitPrice;
}

export function calculatePositionDelta(price, position, sizeDelta) {
  const { size, collateral, isLong, averagePrice, lastIncreasedTime } = position;
  if (!sizeDelta) {
    sizeDelta = size;
  }
  const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice);
  let delta = sizeDelta.mul(priceDelta).div(averagePrice);
  const pendingDelta = delta;

  const minProfitExpired = lastIncreasedTime + MIN_PROFIT_TIME < Date.now() / 1000;
  const hasProfit = isLong ? price.gt(averagePrice) : price.lt(averagePrice);
  if (!minProfitExpired && hasProfit && delta.mul(BASIS_POINTS_DIVISOR).lte(size.mul(MIN_PROFIT_BIPS))) {
    delta = bigNumberify(0);
  }

  const deltaPercentage = delta.mul(BASIS_POINTS_DIVISOR).div(collateral);
  const pendingDeltaPercentage = pendingDelta.mul(BASIS_POINTS_DIVISOR).div(collateral);

  return {
    delta,
    pendingDelta,
    pendingDeltaPercentage,
    hasProfit,
    deltaPercentage,
  };
}

export function getDeltaStr({ delta, deltaPercentage, hasProfit }) {
  let deltaStr;
  let deltaPercentageStr;

  if (delta.gt(0)) {
    deltaStr = hasProfit ? "+" : "-";
    deltaPercentageStr = hasProfit ? "+" : "-";
  } else {
    deltaStr = "";
    deltaPercentageStr = "";
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`;
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`;


  return { deltaStr, deltaPercentageStr };
}

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}) {
  if (!size && !sizeDelta) {
    return;
  }
  if (!collateral && !collateralDelta) {
    return;
  }

  let nextSize = size ? size : bigNumberify(0);
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }
  }

  let remainingCollateral = collateral ? collateral : bigNumberify(0);
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(collateral)) {
        return;
      }
      remainingCollateral = collateral.sub(collateralDelta);
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.add(delta);
    } else {
      if (delta.gt(remainingCollateral)) {
        return;
      }

      remainingCollateral = remainingCollateral.sub(delta);
    }
  }

  if (remainingCollateral.eq(0)) {
    return;
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    : remainingCollateral;
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    remainingCollateral = remainingCollateral.sub(fundingFee);
  }
  return nextSize.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral);
}

export function getLiquidationPrice(data) {
  let {
    isLong,
    size,
    collateral,
    averagePrice,
    entryFundingRate,
    cumulativeFundingRate,
    sizeDelta,
    collateralDelta,
    increaseCollateral,
    increaseSize,
    delta,
    hasProfit,
    includeDelta,
  } = data;
  if (!size || !collateral || !averagePrice) {
    return;
  }

  let nextSize = size ? size : bigNumberify(0);
  let remainingCollateral = collateral;

  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }

    if (includeDelta && !hasProfit) {
      const adjustedDelta = sizeDelta.mul(delta).div(size);
      remainingCollateral = remainingCollateral.sub(adjustedDelta);
    }
  }

  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = remainingCollateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(remainingCollateral)) {
        return;
      }
      remainingCollateral = remainingCollateral.sub(collateralDelta);
    }
  }

  let positionFee = getMarginFee(size).add(LIQUIDATION_FEE);
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    positionFee = positionFee.add(fundingFee);
  }

  const liquidationPriceForFees = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  const liquidationPriceForMaxLeverage = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage;
  }
  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees;
  }

  if (isLong) {
    // return the higher price
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
      ? liquidationPriceForFees
      : liquidationPriceForMaxLeverage;
  }

  // return the lower price
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
    ? liquidationPriceForFees
    : liquidationPriceForMaxLeverage;
}

export function getLiquidationPrice2(data, collateralUsd) {
  let {
    isLong,
    size,
    collateral,
    averagePrice,
    pendingPremiumFee,
    pendingFundingFee,
    pendingPositionFee,
  } = data;
  if (!size || !collateral || !averagePrice) {
    return;
  }
  // posErr = (position.collateral - position.pendingPremiumFee - position.pendingFundingFee - position.pendingPositionFee) * position.averagePrice / position.size
  // liquidationPrice = position.isLong ? position.averagePrice - posErr : position.averagePrice + posErr;


  if (collateralUsd > 0) {
    const posErr = size.eq(0) ? size : (collateral.sub(pendingPremiumFee).sub(pendingFundingFee).sub(pendingPositionFee)).mul(averagePrice).div(size)
    // console.log("collateralUsd > 0")
    return isLong ? averagePrice.sub(posErr) : averagePrice.add(posErr);
  } else if (collateralUsd < 0) {
    // console.log("collateralUsd < 0")
    const posErr = size.eq(0) ? size : (collateral.sub(collateralUsd).sub(pendingPremiumFee).sub(pendingFundingFee).sub(pendingPositionFee)).mul(averagePrice).div(size)
    return isLong ? averagePrice.sub(posErr) : averagePrice.add(posErr);
  }
  const posErr = size.eq(0) ? size : (collateral.sub(pendingPremiumFee).sub(pendingFundingFee).sub(pendingPositionFee)).mul(averagePrice).div(size)
  return isLong ? averagePrice.sub(posErr) : averagePrice.add(posErr);
}

export function getUsd(amount, tokenAddress, max, infoTokens, orderOption, triggerPriceUsd) {
  if (!amount) {
    return;
  }
  if (tokenAddress === USDX_ADDRESS) {
    return amount.mul(PRECISION).div(expandDecimals(1, 18));
  }
  const info = getTokenInfo(infoTokens, tokenAddress);
  const price = getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd);
  if (!price) {
    return;
  }
  return amount.mul(price).div(expandDecimals(1, info.decimals));
}

export function getPositionKey(account, collateralTokenAddress, indexTokenAddress, isLong, nativeTokenAddress) {
  const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
  const tokenAddress1 = indexTokenAddress === AddressZero ? nativeTokenAddress : indexTokenAddress;
  return (account + ":" + tokenAddress0 + ":" + tokenAddress1 + ":" + isLong).toLowerCase();
}

export function getPositionContractKey(account, collateralToken, indexToken, isLong) {
  return ethers.utils.solidityKeccak256(
    ["address", "address", "address", "bool"],
    [account, collateralToken, indexToken, isLong],
  );
}

export function getSwapFeeBasisPoints(isStable, elpName) {
  const swapFeeBasisPointsVal = elpName == "ELP-1" ? SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS2;
  return isStable ? STABLE_SWAP_FEE_BASIS_POINTS : swapFeeBasisPointsVal;
}

// BSC TESTNET
const BSC_TEST_RPC_PROVIDERS = [
  "https://data-seed-prebsc-1-s1.binance.org:8545",
  "https://data-seed-prebsc-2-s1.binance.org:8545",
  "https://data-seed-prebsc-1-s2.binance.org:8545",
  "https://data-seed-prebsc-2-s2.binance.org:8545",
  "https://data-seed-prebsc-1-s3.binance.org:8545",
  "https://data-seed-prebsc-2-s3.binance.org:8545",
];

// BSC MAINNET
export const BSC_RPC_PROVIDERS = [
  "https://bsc-dataseed.binance.org",
  "https://bsc-dataseed1.defibit.io",
  "https://bsc-dataseed1.ninicoin.io",
  "https://bsc-dataseed2.defibit.io",
  "https://bsc-dataseed3.defibit.io",
  "https://bsc-dataseed4.defibit.io",
  "https://bsc-dataseed2.ninicoin.io",
  "https://bsc-dataseed3.ninicoin.io",
  "https://bsc-dataseed4.ninicoin.io",
  "https://bsc-dataseed1.binance.org",
  "https://bsc-dataseed2.binance.org",
  "https://bsc-dataseed3.binance.org",
  "https://bsc-dataseed4.binance.org",
];

const RPC_PROVIDERS = {
  [56]: BSC_RPC_PROVIDERS,
  [97]: BSC_TEST_RPC_PROVIDERS,
  [ARBITRUM]: ARBITRUM_RPC_PROVIDERS,
  [AVALANCHE]: AVALANCHE_RPC_PROVIDERS,
};

const FALLBACK_PROVIDERS = {
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
};

export function shortenAddress(address, length) {
  if (!length) {
    return "";
  }
  if (!address) {
    return address;
  }
  if (address.length < 10) {
    return address;
  }
  let left = Math.floor((length - 3) / 2) + 1;
  return address.substring(0, left) + "..." + address.substring(address.length - (length - (left + 3)), address.length);
}

export function formatDateTime(time) {
  return formatDateFn(time * 1000, "dd MMM yyyy, h:mm a");
}

export function getTimeRemaining(time) {
  const now = parseInt(Date.now() / 1000);
  if (time < now) {
    return "0h 0m";
  }
  const diff = time - now;
  const hours = parseInt(diff / (60 * 60));
  const minutes = parseInt((diff - hours * 60 * 60) / 60);
  return `${hours}h ${minutes}m`;
}

export function formatDate(time) {
  return formatDateFn(time * 1000, "dd MMM yyyy");
}

export function hasMetaMaskWalletExtension() {
  return window.ethereum;
}

export function hasCoinBaseWalletExtension() {
  const { ethereum } = window;

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet) {
    return false;
  }
  return window.ethereum.isCoinbaseWallet || ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
}

export function activateInjectedProvider(providerName) {
  const { ethereum } = window;

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet && !ethereum?.isMetaMask) {
    return undefined;
  }

  let provider;
  if (ethereum?.providers) {
    switch (providerName) {
      case "CoinBase":
        provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
        break;
      case "MetaMask":
      default:
        provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
        break;
    }
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
}

export function getInjectedConnector() {
  return injectedConnector;
}

export function useENS(address) {
  const [ensName, setENSName] = useState();

  useEffect(() => {
    async function resolveENS() {
      if (address) {
        const provider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/eth");
        const name = await provider.lookupAddress(address.toLowerCase());
        if (name) setENSName(name);
      }
    }
    resolveENS();
  }, [address]);

  return { ensName };
}

export function clearWalletConnectData() {
  localStorage.removeItem(WALLET_CONNECT_LOCALSTORAGE_KEY);
}

export function clearWalletLinkData() {
  Object.entries(localStorage)
    .map(x => x[0])
    .filter(x => x.startsWith(WALLET_LINK_LOCALSTORAGE_PREFIX))
    .map(x => localStorage.removeItem(x));
}

export function getProvider(library, chainId) {
  let provider;
  if (library) {
    return library.getSigner();
  }
  provider = _.sample(RPC_PROVIDERS[chainId]);
  return new ethers.providers.StaticJsonRpcProvider(provider, { chainId });
}

export function getFallbackProvider(chainId) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return;
  }

  const provider = _.sample(FALLBACK_PROVIDERS[chainId]);
  return new ethers.providers.StaticJsonRpcProvider(provider, { chainId });
}

export const getContractAddressCall = ({
  provider,
  contractInfo,
  arg0,
  arg1,
  method,
  params,
  additionalArgs,
  onError,
}) => {
  if (method === 'getVaultTokenInfoV4') {
  }
  if (ethers.utils.isAddress(arg0)) {

    const address = arg0;
    const contract = new ethers.Contract(address, contractInfo.abi, provider);
    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs));
    }
    return contract[method](...params);
  }
  if (!provider) {
    return;
  }

  return provider[method](arg1, ...params);
};

// prettier-ignore
export const fetcher = (library, contractInfo, additionalArgs) => (...args) => {
  // eslint-disable-next-line
  const [id, chainId, arg0, arg1, ...params] = args;
  const provider = getProvider(library, chainId);

  const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;
  const contractCall = getContractAddressCall({
    provider,
    contractInfo,
    arg0,
    arg1,
    method,
    params,
    additionalArgs,
  })


  let shouldCallFallback = true

  const handleFallback = async (resolve, reject, error) => {
    if (!shouldCallFallback) {
      return
    }
    // prevent fallback from being called twice
    shouldCallFallback = false

    const fallbackProvider = getFallbackProvider(chainId)
    if (!fallbackProvider) {
      reject(error)
      return
    }

    // console.info("using fallbackProvider for", method)
    const fallbackContractCall = getContractAddressCall({
      provider: fallbackProvider,
      contractInfo,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    })

    fallbackContractCall.then((result) => resolve(result)).catch((e) => {
      console.error("fallback fetcher error", id, contractInfo.contractName, method, e);
      reject(e)
    })
  }

  return new Promise(async (resolve, reject) => {
    contractCall.then((result) => {
      shouldCallFallback = false
      resolve(result)
    }).catch((e) => {
      handleFallback(resolve, reject, e)
    })

    setTimeout(() => {
      handleFallback(resolve, reject, "contractCall timeout")
    }, 2000)
  })

};

export function bigNumberify(n) {
  try {
    return ethers.BigNumber.from(n);
  } catch (e) {
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export function expandDecimals(n, decimals) {
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

export const trimZeroDecimals = amount => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount, maxDecimals) => {
  let amountStr = BigNumber.isBigNumber(amount) ? amount.toFixed() : amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount, minDecimals) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export const formatKeyAmount = (map, key, tokenDecimals, displayDecimals, useCommas) => {
  if (!map || !map[key]) {
    return "...";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};

export const formatArrayAmount = (arr, index, tokenDecimals, displayDecimals, useCommas) => {
  if (!arr || !arr[index]) {
    return "...";
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas);
};

function _parseOrdersData(ordersData, account, indexes, extractor, uintPropsLength, addressPropsLength) {
  if (!ordersData || ordersData.length === 0) {
    return [];
  }
  const [uintProps, addressProps] = ordersData;
  const count = uintProps.length / uintPropsLength;

  const orders = [];
  for (let i = 0;i < count;i++) {
    const sliced = addressProps
      .slice(addressPropsLength * i, addressPropsLength * (i + 1))
      .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)));

    if (sliced[0] === AddressZero && sliced[1] === AddressZero) {
      continue;
    }

    const order = extractor(sliced);
    order.index = indexes[i];
    order.account = account;
    orders.push(order);
  }

  return orders;
}

export function parseDecreaseOrdersData(chainId, decreaseOrdersData, account, indexes, contract) {
  const extractor = sliced => {
    const isLong = sliced[4].toString() === "1";
    return {
      collateralToken: sliced[0],
      indexToken: sliced[1],
      collateralDelta: sliced[2],
      sizeDelta: sliced[3],
      isLong,
      triggerPrice: sliced[5],
      triggerAboveThreshold: sliced[6].toString() === "1",
      type: DECREASE,
      contract
    };
  };
  return _parseOrdersData(decreaseOrdersData, account, indexes, extractor, 5, 2).filter(order => {
    return isValidToken(chainId, order.collateralToken) && isValidToken(chainId, order.indexToken);
  });
}

export function parseIncreaseOrdersData(chainId, increaseOrdersData, account, indexes, contract) {
  const extractor = sliced => {
    const isLong = sliced[5].toString() === "1";
    return {
      purchaseToken: sliced[0],
      collateralToken: sliced[1],
      indexToken: sliced[2],
      purchaseTokenAmount: sliced[3],
      sizeDelta: sliced[4],
      isLong,
      triggerPrice: sliced[6],
      triggerAboveThreshold: sliced[7].toString() === "1",
      type: INCREASE,
      contract
    };
  };
  return _parseOrdersData(increaseOrdersData, account, indexes, extractor, 5, 3)
}

export function parseSwapOrdersData(chainId, swapOrdersData, account, indexes, contract) {
  if (!swapOrdersData || !swapOrdersData.length) {
    return [];
  }

  const extractor = sliced => {
    const triggerAboveThreshold = sliced[6].toString() === "1";
    const shouldUnwrap = sliced[7].toString() === "1";

    return {
      path: [sliced[0], sliced[1], sliced[2]].filter(address => address !== AddressZero),
      amountIn: sliced[3],
      minOut: sliced[4],
      triggerRatio: sliced[5],
      triggerAboveThreshold,
      type: SWAP,
      shouldUnwrap,
      contract
    };
  };
  return _parseOrdersData(swapOrdersData, account, indexes, extractor, 5, 3).filter(order => {
    return order.path.every(token => isValidToken(chainId, token));
  });
}

export function getOrderKey(order) {
  return `${order.type}-${order.account}-${order.index}`;
}

export function useAccountOrders(elpName, flagOrdersEnabled, overrideAccount) {
  const active = true; // this is used in Actions.js so set active to always be true
  // const account = overrideAccount || connectedAccount;
  const account = overrideAccount;
  const { chainID, provider } = useWeb3Context();

  const shouldRequest = active && account && flagOrdersEnabled;
  const orderBookAddress = getOrderBookAddress(chainID, elpName);
  const orderBookReaderAddress = getContractAddress(chainID, "OrderBookReader");
  const key = shouldRequest ? [active, chainID, orderBookAddress, account] : false;
  const {
    data: orders = [],
    mutate: updateOrders,
    error: ordersError,
  } = useSWR(key, {
    dedupingInterval: 5000,
    fetcher: async (active, chainID, orderBookAddress, account) => {
      const orderBookContract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
      const orderBookReaderContract = new ethers.Contract(orderBookReaderAddress, OrderBookReader.abi, provider);

      const fetchIndexesFromServer = () => {
        return { swap: [], increase: [], decrease: [] }
      };

      const fetchLastIndex = async type => {
        const method = type.toLowerCase() + "OrdersIndex";

        const result = await orderBookContract[method](account)
          .then(res => bigNumberify(res._hex).toNumber())
          .catch(err => console.error("fetchLastIndex err:", err));
        return result;
      };

      const fetchLastIndexes = async () => {
        const [swap, increase, decrease] = await Promise.all([
          fetchLastIndex("swap"),
          fetchLastIndex("increase"),
          fetchLastIndex("decrease"),
        ]);

        return { swap, increase, decrease };
      };

      const getRange = (to, from) => {
        const LIMIT = 10;
        const _indexes = [];
        from = from || Math.max(to - LIMIT, 0);
        for (let i = to - 1;i >= from;i--) {
          _indexes.push(i);
        }
        return _indexes;
      };

      const getIndexes = (knownIndexes, lastIndex) => {
        if (knownIndexes.length === 0) {
          return getRange(lastIndex);
        }
        return [
          ...knownIndexes,
          ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort((a, b) => b - a),
        ];
      };

      const getOrders = async (method, knownIndexes, lastIndex, parseFunc) => {
        const indexes = getIndexes(knownIndexes, lastIndex);
        const ordersData = await orderBookReaderContract[method](orderBookAddress, account, indexes);

        const orders = parseFunc(chainID, ordersData, account, indexes);

        return orders;
      };

      try {
        const [serverIndexes, lastIndexes] = await Promise.all([fetchIndexesFromServer(), fetchLastIndexes()]);
        const [swapOrders = [], increaseOrders = [], decreaseOrders = []] = await Promise.all([
          getOrders("getSwapOrders", serverIndexes.swap, lastIndexes.swap, parseSwapOrdersData),
          getOrders("getIncreaseOrders", serverIndexes.increase, lastIndexes.increase, parseIncreaseOrdersData),
          getOrders("getDecreaseOrders", serverIndexes.decrease, lastIndexes.decrease, parseDecreaseOrdersData),
        ]);
        console.log("increaseOrders", increaseOrders)
        return [...swapOrders, ...increaseOrders, ...decreaseOrders];
      } catch (ex) {
        console.error("fetch orders: ", ex);
      }
    },
  });
  return [orders, updateOrders, ordersError];
}

export const formatAmount = (amount, tokenDecimals, displayDecimals, useCommas, defaultValue) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatAmount2 = (amount, displayDecimals, useCommas, defaultValue) => {
  if (!defaultValue) {
    defaultValue = "- -";
  }
  if (BigNumber.isBigNumber(amount) && amount.isNaN()) {
    return 0
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = limitDecimals(amount, displayDecimals);
  if (useCommas) {
    return numberWithCommas(amountStr);
  }

  return amountStr;
};

export const formatAmountFree = (amount, tokenDecimals, displayDecimals) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value, tokenDecimals) => {
  const pValue = parseFloat(value);
  if (isNaN(pValue)) {
    return undefined;
  }
  value = limitDecimals(value, tokenDecimals);
  const amount = ethers.utils.parseUnits(value, tokenDecimals);
  return bigNumberify(amount);
};

export function numberWithCommas(x) {
  if (!x) {
    return "- -";
  }
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function getEDETokenUrl(chainId) {
  if (chainId === 56) {
    return "https://www.dextools.io/app/en/bnb/pair-explorer/0xd14e0b9eba0010e97f57cbd9042215499b53bc47s";
  } else if (chainId === 97) {
    return "https://www.dextools.io/app/en/bnb/pair-explorer/0xd14e0b9eba0010e97f57cbd9042215499b53bc47s";
  } else if (chainId === 42161) {
    return "https://www.dextools.io/app/cn/arbitrum/pair-explorer/0x7CA686B3795784f12643127c5c3F56aa107a04C3";
  } else if (chainId === 421613) {
    return "https://www.dextools.io/app/cn/arbitrum/pair-explorer/0x7CA686B3795784f12643127c5c3F56aa107a04C3";
  }
  return "https://www.dextools.io/app/en/bnb/pair-explorer/0xd14e0b9eba0010e97f57cbd9042215499b53bc47s";
}

export function getEDEBuyUrl(chainId, busdAddress, edeAddress) {
  if (chainId === 56) {
    return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${busdAddress}&outputCurrency=${edeAddress}`;
  } else if (chainId === 97) {
    return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${busdAddress}&outputCurrency=${edeAddress}`;
  } else if (chainId === 42161) {
    return `https://app.camelot.exchange/?token2=${edeAddress}`;
  } else if (chainId === 421613) {
    return `https://app.camelot.exchange/?token2=${edeAddress}`;
  }
  return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${busdAddress}&outputCurrency=${edeAddress}`;
}

export function getSellUrl_aEDE(chainId, aEDEAddress, edeAddress) {
  if (chainId === 56) {
    return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${aEDEAddress}&outputCurrency=${edeAddress}`;
  } else if (chainId === 97) {
    return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${aEDEAddress}&outputCurrency=${edeAddress}`;
  } else if (chainId === 42161) {
    return `https://app.camelot.exchange/?token1=${aEDEAddress}&token2=${edeAddress}`;
  } else if (chainId === 421613) {
    return `https://app.camelot.exchange/?token1=${aEDEAddress}&token2=${edeAddress}`;
  }
  return `https://pancakeswap.finance/swap?chainId=${chainId}&inputCurrency=${aEDEAddress}&outputCurrency=${edeAddress}`;
}

export function getExplorerUrl(chainId) {
  if (chainId === 56) {
    return "https://bscscan.com/";
  } else if (chainId === 97) {
    return "https://testnet.bscscan.com/";
  } else if (chainId === 42161) {
    return "https://arbiscan.io/";
  } else if (chainId === 421613) {
    return "https://goerli.arbiscan.io/";
  }
  return "https://basescan.org/";
}

export function getAccountUrl(chainId, account) {
  if (!account) {
    return getExplorerUrl(chainId);
  }
  return getExplorerUrl(chainId) + "address/" + account;
}

export function getTokenUrl(chainId, address) {
  if (!address) {
    return getExplorerUrl(chainId);
  }
  return getExplorerUrl(chainId) + "token/" + address;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export async function setGasPrice(txnOpts, provider, chainId) {
  let maxGasPrice = MAX_GAS_PRICE_MAP[chainId];
  const premium = GAS_PRICE_ADJUSTMENT_MAP[chainId] || bigNumberify(0);

  const gasPrice = await provider.getGasPrice();

  if (maxGasPrice) {
    if (gasPrice.gt(maxGasPrice)) {
      maxGasPrice = gasPrice;
    }

    const feeData = await provider.getFeeData();

    // the wallet provider might not return maxPriorityFeePerGas in feeData
    // in which case we should fallback to the usual getGasPrice flow handled below
    if (feeData && feeData.maxPriorityFeePerGas) {
      txnOpts.maxFeePerGas = maxGasPrice;
      txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.add(premium);
      return;
    }
  }

  txnOpts.gasPrice = gasPrice.add(premium);
  return;
}

export async function getGasLimit(contract, method, params = [], value) {
  const defaultValue = bigNumberify(0);

  if (!value) {
    value = defaultValue;
  }
  let gasLimit = await contract.estimateGas[method](...params, { value });

  if (gasLimit.lt(22000)) {
    gasLimit = bigNumberify(22000);
  }

  return gasLimit.mul(11000).div(10000); // add a 10% buffer
}

export async function approveTokens({
  setIsApproving,
  library,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  getTokenInfo,
  infoTokens,
  pendingTxns,
  setPendingTxns,
  includeMessage,
}) {
  setIsApproving(true);
  const contract = new ethers.Contract(tokenAddress, Token.abi, library.getSigner());
  let failMsg;
  let successMsg;
  let res;
  try {
    res = await contract.approve(spender, ethers.constants.MaxUint256);
    if (onApproveSubmitted) {
      onApproveSubmitted();
    }
    if (getTokenInfo && infoTokens && pendingTxns && setPendingTxns) {
      const token = getTokenInfo(infoTokens, tokenAddress) ?? { symbol: 'ELP' };
      const pendingTxn = {
        hash: res.hash,
        message: includeMessage ? `${token ? token.symbol : 'ELP'} Approved!` : false,
      };
      successMsg = `${token.symbol} Approved!`;
      setPendingTxns([...pendingTxns, pendingTxn]);
    }
    await res.wait();
    return res;
  } catch (e) {
    const rpcError = serializeError(e);
    console.log('[rpcError]:', e);
    let msg = rpcError.data && rpcError.data.originalError ? rpcError.data.originalError.reason : rpcError.message;
    if (
      ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"].includes(
        e.data?.message,
      )
    ) {
      failMsg = "There is not enough ETH in your account on Arbitrum to send this transaction.";
    } else if (e.message?.includes("User denied transaction signature")) {
      failMsg = "Approval was cancelled";
    } else {
      failMsg = msg; //  "Approval failed";
    }
    toastError(failMsg);
  } finally {
    setIsApproving(false);
    if (res) {
      toastSuccess(successMsg);
    }
  }
}

export const shouldRaiseGasError = (token, amount) => {
  if (!amount) {
    return false;
  }
  if (token.address !== AddressZero) {
    return false;
  }
  if (!token.balance) {
    return false;
  }
  if (amount.gte(token.balance)) {
    return true;
  }
  if (token.balance.sub(amount).lt(DUST_BNB)) {
    return true;
  }
  return false;
};

export const getTokenInfo = (infoTokens, tokenAddress, replaceNative, nativeTokenAddress) => {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[AddressZero];
  }
  return infoTokens[tokenAddress?.toLowerCase()];
};

const NETWORK_METADATA = {
  [56]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: BSC_RPC_PROVIDERS,
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [97]: {
    chainId: "0x" + TESTNET.toString(16),
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: BSC_TEST_RPC_PROVIDERS,
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [421613]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ARBITRUM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(ARBITRUM_TEST)],
  },
  [42161]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ARBITRUM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
};

export const addBscNetwork = async () => {
  return addNetwork(NETWORK_METADATA[MAINNET]);
};

export const addNetwork = async metadata => {
  await window.ethereum.request({ method: "wallet_addEthereumChain", params: [metadata] }).catch();
};

export function useChainId() {
  let { chainId } = useWeb3React();

  if (!chainId) {
    const chainIdFromLocalStorage = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
    if (chainIdFromLocalStorage) {
      chainId = parseInt(chainIdFromLocalStorage);
      if (!chainId) {
        // localstorage value is invalid
        localStorage.removeItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
      }
    }
  }

  if (!chainId || !supportedChainIds.includes(chainId)) {
    chainId = DEFAULT_CHAIN_ID;
  }
  return { chainId };
}

export const switchNetwork = async (chainId, active) => {
  if (!active) {
    // chainId in localStorage allows to switch network even if wallet is not connected
    // or there is no wallet at all
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, chainId);
    document.location.reload();
    return;
  }

  try {
    const chainIdHex = "0x" + chainId.toString(16);
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainIdHex }],
    });
    // helperToast.success("Connected to " + getChainName(chainId));
    toastSuccess("Connected to " + getChainName(chainId));
    return getChainName(chainId);
  } catch (ex) {
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    // This error code indicates that the chain has not been added to MetaMask.
    // 4001 error means user has denied the request
    // If the error code is not 4001, then we need to add the network
    if (ex.code !== 4001) {
      return await addNetwork(NETWORK_METADATA[chainId]);
    }

    console.error("error", ex);
  }
};

export const getWalletConnectHandler = (activate, deactivate, setActivatingConnector) => {
  const fn = async () => {
    const walletConnect = getWalletConnectConnector();
    setActivatingConnector(walletConnect);
    activate(walletConnect, ex => {
      if (ex instanceof UnsupportedChainIdError) {
        // helperToast.error("Unsupported chain. Switch to Arbitrum network on your wallet and try again");
        toastError("Unsupported chain. Switch to Arbitrum network on your wallet and try again");
        console.warn(ex);
      } else if (!(ex instanceof UserRejectedRequestErrorWalletConnect)) {
        // helperToast.error(ex.message);
        toastError(ex.message);
        console.warn(ex);
      }
      clearWalletConnectData();
      deactivate();
    });
  };
  return fn;
};

export const getInjectedHandler = activate => {
  const fn = async () => {
    activate(getInjectedConnector(), e => {
      const chainId = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;

      if (e instanceof UnsupportedChainIdError) {
        helperToast.error(
          <div>
            <div>Your wallet is not connected to {getChainName(chainId)}.</div>
            <br />
            <div className="clickable underline margin-bottom" onClick={() => switchNetwork(chainId, true)}>
              Switch to {getChainName(chainId)}
            </div>
            <div className="clickable underline" onClick={() => switchNetwork(chainId, true)}>
              Add {getChainName(chainId)}
            </div>
          </div>,
        );
        return;
      }
      const errString = e.message ?? e.toString();
      // helperToast.error(errString);
      toastError(errString);
    });
  };
  return fn;
};

export function isMobileDevice(navigator) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function setTokenUsingIndexPrices(token, indexPrices, nativeTokenAddress) {
  if (!indexPrices) {
    return;
  }
  const tokenSymbol = token.symbol;
  const indexPrice = indexPrices[tokenSymbol + "USDT"];
  if (!indexPrice) {
    return;
  }

  const indexPriceBn = bigNumberify(indexPrice);
  if (indexPriceBn.eq(0)) {
    return;
  }

  const spread = token.maxPrice.sub(token.minPrice);
  const spreadBps = spread.mul(BASIS_POINTS_DIVISOR).div(token.maxPrice.add(token.minPrice).div(2));

  if (spreadBps.gt(MAX_PRICE_DEVIATION_BASIS_POINTS - 50)) {
    // only set one of the values as there will be a spread between the index price and the Chainlink price
    if (indexPriceBn.gt(token.minPrimaryPrice)) {
      token.maxPrice = indexPriceBn;
    } else {
      token.minPrice = indexPriceBn;
    }
    return;
  }

  const halfSpreadBps = spreadBps.div(2).toNumber();
  token.maxPrice = indexPriceBn;
  token.minPrice = indexPriceBn;
  // token.maxPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR + halfSpreadBps).div(BASIS_POINTS_DIVISOR);
  // token.minPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR - halfSpreadBps).div(BASIS_POINTS_DIVISOR);
}

export function getInfoTokens(
  tokens,
  tokenBalances,
  whitelistedTokens,
  vaultTokenInfo,
  fundingRateInfo,
  vaultPropsLength,
  indexPrices,
  nativeTokenAddress,
) {
  if (!vaultPropsLength) {
    vaultPropsLength = 15;
  }
  const fundingRatePropsLength = 2;
  const infoTokens = {};
  for (let i = 0;i < tokens.length;i++) {
    const token = JSON.parse(JSON.stringify(tokens[i]));
    if (tokenBalances) {
      token.balance = tokenBalances[i];
    }
    if (token.address === USDX_ADDRESS) {
      token.minPrice = expandDecimals(1, USD_DECIMALS);
      token.maxPrice = expandDecimals(1, USD_DECIMALS);
    }
    infoTokens[token.address.toLowerCase()] = token;
  }

  for (let i = 0;i < whitelistedTokens.length;i++) {
    const tokenss = JSON.parse(JSON.stringify(whitelistedTokens[i]));
    const tokenMap = vaultTokenInfo[tokenss.address.toLowerCase()] ?? {}
    const token = { ...tokenss, ...tokenMap }
    if (vaultTokenInfo[token.address.toLowerCase()]) {
      // const tokens = vaultTokenInfo[token.address.toLowerCase()];
      // token.poolAmount = tokens.poolAmount
      // token.reservedAmount = tokens.reservedAmount
      // token.availableAmount = tokens.availableAmount
      // token.usdxAmount = tokens.usdxAmount
      // token.redemptionAmount = tokens.redemptionAmount
      // token.weight = token.weight
      // token.bufferAmount = token.bufferAmount
      // token.maxUsdxAmount = token.maxUsdxAmount
      // token.globalShortSize = token.globalShortSize
      // token.maxGlobalShortSize = token.maxGlobalShortSize
      // token.maxGlobalLongSize = token.maxGlobalLongSize
      // token.minPrice = token.minPrice
      // token.maxPrice = token.maxPrice
      // token.guaranteedUsd = token.guaranteedUsd
      // token.maxPrimaryPrice = token.maxPrimaryPrice 
      // token.minPrimaryPrice = token.minPrimaryPrice

      // console.log('[vaultTokenInfo]:', Number(token.minPrice) / 10 ** 30);

      // save minPrice and maxPrice as setTokenUsingIndexPrices may override it
      token.contractMinPrice = token.minPrice;
      token.contractMaxPrice = token.maxPrice;


      if (token.maxUsdxAmount.eq(0)) {
        token.maxUsdxAmount = DEFAULT_MAX_USDX_AMOUNT;
      }

      token.availableUsd = token.isStable
        ? token.poolAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals))
        : token.availableAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals));

      // token.maxAvailableShort = bigNumberify(0);
      // token.hasMaxAvailableShort = false;
      // if (token.maxGlobalShortSize.gt(0)) {
      //   token.hasMaxAvailableShort = true;
      //   if (token.maxGlobalShortSize.gt(token.globalShortSize)) {
      //     token.maxAvailableShort = token.maxGlobalShortSize.sub(token.globalShortSize);
      //     // token.remainingShortSize = pool_usdt.sub(token.globalShortSize);
      //   }
      // }

      // token.maxAvailableLong = bigNumberify(0);
      // token.hasMaxAvailableLong = false;
      // if (token.maxGlobalLongSize.gt(0)) {
      //   token.hasMaxAvailableLong = true;
      //   if (token.maxGlobalLongSize.gt(token.guaranteedUsd)) {
      //     const remainingLongSize = token.maxGlobalLongSize.sub(token.guaranteedUsd);
      //     token.maxAvailableLong = remainingLongSize.lt(token.availableUsd) ? remainingLongSize : token.availableUsd;
      //   }
      // } else {
      //   token.maxAvailableLong = token.availableUsd;
      // }
      // token.maxLongCapacity =
      //   token.maxGlobalLongSize.gt(0) && token.maxGlobalLongSize.lt(token.availableUsd)
      //     ? token.maxGlobalLongSize
      //     : token.availableUsd.add(token.guaranteedUsd);;

      token.managedUsd = token.availableUsd.add(token.guaranteedUsd);
      // todo 
      if (token.minPrice > 0) {
        token.managedAmount = token.managedUsd.mul(expandDecimals(1, token.decimals)).div(token.minPrice);
        setTokenUsingIndexPrices(token, indexPrices, nativeTokenAddress);
      }
    }

    token.longRatePerHour = bigNumberify(0);
    token.shortRatePerHour = bigNumberify(0);
    token.maxAvailableShort = bigNumberify(0);
    token.hasMaxAvailableShort = true;
    token.maxAvailableLong = bigNumberify(0);
    token.fundingRate = bigNumberify(0);
    if (fundingRateInfo) {
      token.longRatePerHour = fundingRateInfo[i].longRatePerHour;
      token.shortRatePerHour = fundingRateInfo[i].shortRatePerHour;
      token.fundingRate = fundingRateInfo[i].shortRatePerHour;
      token.fundingRate = fundingRateInfo[i].fundingRatePerHour
      token.globalShortSize = fundingRateInfo[i].shortSize;
      token.maxGlobalShortSize = fundingRateInfo[i].maxShortSize;
      token.globalLongSize = fundingRateInfo[i].longSize;
      token.maxGlobalLongSize = fundingRateInfo[i].maxLongSize;

      // token.poolSize = fundingRateInfo[i].poolSize
      const longSize = fundingRateInfo[i].shortSize < 0 ? bigNumberify(0) : fundingRateInfo[i].shortSize;
      token.poolSize = token.poolAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals))
      token.longSize = fundingRateInfo[i].longSize
      token.shortSize = fundingRateInfo[i].shortSize

      const availableUsd = token.poolSize.sub(fundingRateInfo[i].longSize)
      const availableUsd2 = fundingRateInfo[i].poolAmount.sub(fundingRateInfo[i].reservedAmount).mul(token.minPrice).div(expandDecimals(1, token.decimals));

      const remainingLongSize = fundingRateInfo[i].maxLongSize.sub(fundingRateInfo[i].longSize).gt(0) ? fundingRateInfo[i].maxLongSize.sub(fundingRateInfo[i].longSize) : bigNumberify(0);
      let data;
      if (availableUsd2.gt(remainingLongSize)) {
        data = availableUsd2.add(longSize);
      } else {
        data = remainingLongSize.add(longSize);
      }
      //  最大开仓量=max(   （poolamount-reservedAmount）* 价格 ， 合约设置的最大开仓量-当前开仓量 ）  +  当前开仓量
      token.maxAvailableLong = data;

      // if (fundingRateInfo[i].maxLongSize.gt(0)) {
      //   if (token?.isGNS) {
      //     token.maxAvailableLong = remainingLongSize;

      //   } else {
      //     token.maxAvailableLong = remainingLongSize.lt(availableUsd) ? remainingLongSize : availableUsd

      //   }
      // } else {
      //   if (token?.isGNS) {

      //     token.maxAvailableLong = bigNumberify(0)

      //   } else {
      //     token.maxAvailableLong = availableUsd

      //   }

      // }
      token.maxLongCapacity =
        fundingRateInfo[i].maxLongSize.gt(0) && fundingRateInfo[i].maxLongSize.lt(availableUsd)
          ? fundingRateInfo[i].maxLongSize
          : token.poolSize
      const shortSize = fundingRateInfo[i].shortSize < 0 ? bigNumberify(0) : fundingRateInfo[i].shortSize;

      const availableShortUsd = fundingRateInfo[i].availSize.sub(shortSize)
      const remainingShortSize = fundingRateInfo[i]?.maxShortSize.sub(shortSize).gt(0) ? fundingRateInfo[i].maxShortSize.sub(shortSize) : bigNumberify(0);



      let data2;
      if (availableUsd2.gt(remainingShortSize)) {
        data2 = availableUsd2.add(shortSize);
      } else {
        data2 = remainingShortSize.add(shortSize);
      }
      token.maxAvailableShort = data2;
      // if (fundingRateInfo[i].maxShortSize.gt(0)) {
      //   // token.hasMaxAvailableShort = true;
      //   // token.maxAvailableShort = remainingShortSize.lt(availableShortUsd) ? remainingShortSize : availableShortUsd
      //   token.maxAvailableShort = availableShortUsd
      // } else {
      //   token.maxAvailableShort = availableShortUsd
      // }
      token.maxShortCapacity =
        fundingRateInfo[i].maxShortSize.gt(0) && fundingRateInfo[i].maxShortSize.lt(availableShortUsd)
          ? fundingRateInfo[i].maxShortSize
          : availableShortUsd;

    }
    // console.log("fundingRateInfo  --- maxAvailableLong  ", fundingRateInfo, token.maxAvailableLong)

    if (infoTokens[token.address.toLowerCase()]) {
      token.balance = infoTokens[token.address.toLowerCase()].balance;
    }

    infoTokens[token.address.toLowerCase()] = token;
  }
  return infoTokens;
}

export const CHART_PERIODS = {
  "5m": 60 * 5,
  "15m": 60 * 15,
  "1h": 60 * 60,
  "4h": 60 * 60 * 4,
  "1d": 60 * 60 * 24,
};

export function getTotalVolumeSum(volumes) {
  if (!volumes || volumes.length === 0) {
    return;
  }

  let volume = bigNumberify(0);
  for (let i = 0;i < volumes.length;i++) {
    volume = volume.add(volumes[i].data.volume);
  }

  return volume;
}

export async function addTokenToMetamask(token) {
  try {
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    });
    if (wasAdded) {
      // https://github.com/MetaMask/metamask-extension/issues/11377
      // We can show a toast message when the token is added to metamask but because of the bug we can't. Once the bug is fixed we can show a toast message.
    }
  } catch (error) {
    console.error(error);
  }
}

export function sleep(ms) {
  return new Promise(resolve => resolve(), ms);
}

export function getPageTitle(data) {
  return `${data} | Decentralized
  Perpetual Exchange | EDE`;
}

export function isHashZero(value) {
  return value === ethers.constants.HashZero;
}
export function isAddressZero(value) {
  return value === ethers.constants.AddressZero;
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function isDevelopment() {
  return !window.location.host?.includes("gmx.io") && !window.location.host?.includes("ipfs.io");
}

export function isLocal() {
  return window.location.host?.includes("localhost");
}

export function importImage(name) {
  let tokenImage = null;
  try {
    tokenImage = require("../img/" + name);
  } catch (error) {
    tokenImage = require("../img/ic_eth_40.svg");
    console.error(error);
  }
  return tokenImage && tokenImage.default;
}

export function getTwitterIntentURL(text, url = "", hashtag = "") {
  let finalURL = "https://twitter.com/intent/tweet?text=";
  if (text.length > 0) {
    finalURL += encodeURIComponent(text.replace(/[\r\n]+/g, " "))
      .replace(/\*%7C/g, "*|URL:")
      .replace(/%7C\*/g, "|*");

    if (hashtag.length > 0) {
      finalURL += "&hashtags=" + encodeURIComponent(hashtag.replace(/#/g, ""));
    }
    if (url.length > 0) {
      finalURL += "&url=" + encodeURIComponent(url);
    }
  }
  return finalURL;
}

export function isValidTimestamp(timestamp) {
  return new Date(timestamp).getTime() > 0;
}

export function getPositionForOrder(account, order, positionsMap) {
  const key = getPositionKey(account, order.collateralToken, order.indexToken, order.isLong);
  const position = positionsMap[key];
  return position && position.size && position.size.gt(0) ? position : null;
}

export function getOrderError(account, order, positionsMap, position) {
  if (order.type !== DECREASE) {
    return;
  }

  const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap);

  if (!positionForOrder) {
    return "No open position, order cannot be executed unless a position is opened";
  }
  if (positionForOrder.size.lt(order.sizeDelta)) {
    return "Order size is bigger than position, will only be executable if position increases";
  }

  if (positionForOrder.size.gt(order.sizeDelta)) {
    if (positionForOrder.size.sub(order.sizeDelta).lt(positionForOrder.collateral.sub(order.collateralDelta))) {
      return "Order cannot be executed as it would reduce the position's leverage below 1";
    }
    if (positionForOrder.size.sub(order.sizeDelta).lt(expandDecimals(5, USD_DECIMALS))) {
      return "Order cannot be executed as the remaining position would be smaller than $5.00";
    }
  }
}

export function arrayURLFetcher(...urlArr) {
  const fetcher = url => fetch(url).then(res => res.json());
  return Promise.all(urlArr.map(fetcher));
}

export function shouldShowRedirectModal(timestamp) {
  const thirtyDays = 1000 * 60 * 60 * 24 * 30;
  const expiryTime = timestamp + thirtyDays;
  return !isValidTimestamp(timestamp) || Date.now() > expiryTime;
}

export function execInc(methName, params, chainId, address) {
  const baseUrl = "https://data.ede.finance/exec";
  return axios.post(baseUrl, {
    methName,
    params,
    chainId,
    address
  });
};

export async function getElp1StakeRewards(roundId,
  address, chainId) {
  const baseUrl = "https://data.ede.finance/elpStakeInfoTree";
  return await axios.post(baseUrl, {
    roundId,
    address,
    chainId,
    // "roundId": 19554,
    // "address": "0xd25f3ff4d63179800dce837dc5412dac1ba6133f",
    // "chainID": 42161
  });
};

export function getPositionSignature(chainId) {
  const baseUrl = "https://data.ede.finance/signprice";
  return axios.post(baseUrl, {
    chainId,
  });
};

export function getPositionSignaturegns(chainId, tokenAddress) {
  const baseUrl = "https://data.ede.finance/gnssignprice";
  return axios.post(baseUrl, {
    chainId,
    tokenAddress,
  });
};