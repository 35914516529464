import { ethers } from "ethers";

const { parseEther } = ethers.utils;

const constants = {
  [56]: {
    nativeTokenSymbol: "BNB",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
  },
  [97]: {
    nativeTokenSymbol: "BNB",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.006"),
  },
  [421613]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
  [42161]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
  [84531]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
  [8453]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
};

export const getConstant = (chainId, key) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }
  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }
  return constants[chainId][key];
};
