import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useMemo } from "react";
import { SupportedChainId } from "src/constants/chainENV/chains.base";
import { useWeb3Context } from "src/hooks";
export const BSCgraphEndpointUrl = `https://api.thegraph.com/subgraphs/name/metaverseblock/ede_stats_elpall`;

export const ARBgraphPointUrl = `https://api.thegraph.com/subgraphs/name/metaverseblock/stats_arb_all`;

export const BSCelp1Url = `https://api.thegraph.com/subgraphs/name/metaverseblock/ede_stats_elp1`;
export const BSCelp2Url = `https://api.thegraph.com/subgraphs/name/metaverseblock/ede_stats_elp2`;
export const BSCelp1pUrl = `https://api.thegraph.com/subgraphs/name/metaverseblock/ede_stats_elp1_p`;

export function createClient(uri: string) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

export const graphChainIDUrl = {
  [SupportedChainId.ARBITRUM]: ARBgraphPointUrl,
  [SupportedChainId.BSCMAINNET]: BSCgraphEndpointUrl,
  [SupportedChainId.ARB_TEST]: ARBgraphPointUrl,
  [SupportedChainId.BSCTEST]: BSCgraphEndpointUrl,
  [SupportedChainId.BASE_TEST]: BSCgraphEndpointUrl,
  [SupportedChainId.BASE_MAINNET]:
    "https://api.studio.thegraph.com/query/51000/base_stats/version/latest",
};

export function queryGraphUrl(chainID: SupportedChainId) {
  return graphChainIDUrl[chainID];
}

export function useVolumeChartGraphUrl() {
  const { chainID }: { chainID: SupportedChainId } = useWeb3Context();
  return useMemo(() => {
    return createClient(graphChainIDUrl[chainID]);
  }, [chainID]);
}

// export const api = createApi({
//   reducerPath: "dataApi",
//   baseQuery: graphqlRequestBaseQuery(),
//   endpoints: builder => ({}),
// });

// function graphqlRequestBaseQuery(): BaseQueryFn<
//   { document: string | DocumentNode; variables?: any },
//   unknown,
//   Pick<ClientError, "name" | "message" | "stack">,
//   Partial<Pick<ClientError, "request" | "response">>
// > {
//   return async ({ document, variables }, { getState }) => {
//     try {
//       const chainId = window.localStorage.getItem("SELECTED_NETWORK");
//       const chain = Number(chainId) as undefined | SupportedChainId;
//       const subgraphUrl = chain ? graphChainIDUrl[chain] : undefined;
//       if (!subgraphUrl) {
//         return {
//           error: {
//             name: "UnsupportedChainId",
//             message: `Subgraph queries against ChainId ${chain} are not supported.`,
//             stack: "",
//           },
//         };
//       }

//       return { data: await new GraphQLClient(subgraphUrl).request(document, variables), meta: {} };
//     } catch (error) {
//       if (error instanceof ClientError) {
//         const { name, message, stack, request, response } = error;
//         return { error: { name, message, stack }, meta: { request, response } };
//       }
//       throw error;
//     }
//   };
// }
