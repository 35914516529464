import { SupportedChainId } from "./chainENV/chains.base";
export type AddressMap = { [chainId: number]: string };
export const AddressZero = "0x0000000000000000000000000000000000000000";

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.ARB_TEST]: "0x259D00Aab414B0e12E13b027Ef317204759C78Eb",
  [SupportedChainId.BSCTEST]: "0x87648372948f8e1a83da5e92c01afd8b6cee73a3",
  [SupportedChainId.ARBITRUM]: "0xB6AA4C228Df4f96DFA269a5D10658f24D59E4C30",
  [SupportedChainId.BSCMAINNET]: "0x67320a56e188b7b1d578ec10b7665b7a21a3ebc2",
  [SupportedChainId.BASE_TEST]: "0x3a17eE41bfa4Fa72bD9C2b5b280f9F685Fd2c8E0",
  [SupportedChainId.BASE_MAINNET]: "0x6632249F4752B31C6E3E138FFAA98a9Ab45475eD",
};
