import { useWeb3Context } from "./web3Context";
import {
  useEDEAuctionContract,
  useEDEAuctionFactoryContract,
  useTokenContract,
} from "./useContract";
import { useMemo } from "react";
import { toFromBN } from "src/utils/bn";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { addresses } from "src/configs/constants";

export const useAucLen = () => {
  const EDEAuctionFactoryContract = useEDEAuctionFactoryContract();
  const { result } = useSingleCallResult(
    EDEAuctionFactoryContract,
    "totalAuctions"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useAuctionsAddress = () => {
  const aucLen = useAucLen();
  const EDEAuctionFactoryContract = useEDEAuctionFactoryContract();
  if (aucLen) {
  }
  const { result, loading, error } = useSingleCallResult(
    EDEAuctionFactoryContract,
    "auctions",
    [aucLen ? Number(aucLen) - 1 : 0]
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useAuctionsAddress2 = () => {
  const aucLen = useAucLen();
  const EDEAuctionFactoryContract = useEDEAuctionFactoryContract();
  if (aucLen) {
  }
  const { result, loading } = useSingleCallResult(
    EDEAuctionFactoryContract,
    "auctions",
    [aucLen ? Number(aucLen) - 1 : 0]
  );
  return useMemo(() => {
    if (!result) return { result: undefined, loading };
    const data = result[0];
    return {
      result: data,
      loading,
    };
  }, [loading, result]);
};

export const useAuctionContract_EDE = (auctionsAddress?: string) => {
  const contractAddress = useAuctionsAddress();
  const EDEAuctionContract = useEDEAuctionContract(
    auctionsAddress ? auctionsAddress : contractAddress
  );
  if (contractAddress) return EDEAuctionContract;
};

export const useAuctionAmount = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "totalTokens");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useRemainTokens = () => {
  // const EDEAuctionContract = useAuctionContract_EDE();
  // const { result } = useSingleCallResult(
  //   EDEAuctionContract,
  //   "remainCommitment"
  // );
  // return useMemo(() => {
  //   if (!result) return;
  //   const data = result[0];
  //   return toFromBN(data);
  // }, [result]);

  const auctionAmount = useAuctionAmount();
  const amountRaised = useAmountRaised();
  const clearingPrice = useClearingPrice();
  return useMemo(() => {
    if (auctionAmount && amountRaised && clearingPrice)
      return auctionAmount.minus(amountRaised.div(clearingPrice));
  }, [auctionAmount, amountRaised, clearingPrice]);
};

export const useTokenPrice = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "tokenPrice");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useAmountRaised = () => {
  const { chainID } = useWeb3Context();
  const WETHContract = useTokenContract(addresses[chainID].WETH);
  const auctionAddress = useAuctionsAddress();
  const { result } = useSingleCallResult(WETHContract, "balanceOf", [
    auctionAddress,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useStartTime = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "startTime");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useEndTime = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "endTime");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useFloorPrice = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "minimumPrice");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useClearingPrice = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "clearingPrice");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useStartPrice = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "startPrice");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useCommitted = () => {
  const { address } = useWeb3Context();
  const EDEAuctionContract = useAuctionContract_EDE();
  const { result } = useSingleCallResult(EDEAuctionContract, "commitments", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEstimatedReceive = () => {
  const EDEAuctionContract = useAuctionContract_EDE();
  const committed = useCommitted();
  const totalTokens = useAuctionAmount();
  const clearingPrice = useClearingPrice();
  const { result } = useSingleCallResult(
    EDEAuctionContract,
    "commitmentsTotal"
  );
  const commitmentsTotalData = useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);

  return useMemo(() => {
    if (committed && clearingPrice)
      // return totalTokens * (committed / commitmentsTotalData);
      return committed.div(clearingPrice);
  }, [clearingPrice, committed]);
};

export const useTokensClaimable = (auctionsAddress: any) => {
  const { address } = useWeb3Context();
  const EDEAuctionContract = useAuctionContract_EDE(auctionsAddress);
  const { result } = useSingleCallResult(
    EDEAuctionContract,
    "tokensClaimable",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
