import moment from "moment";
import { useMemo } from "react";

import { addresses as ADDRESS } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT, bigNumberify } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN, toFromBN } from "src/utils/bn";
import { useGetEdePrice } from "./getPrice_ede";
import {
  useAEDEContract,
  useEDEContract,
  useEdeDistributionContract,
  useEusdContract,
  useEusdDistributionContract,
  useInfoHelperContract,
  useOLDVeStakerContract,
  useOldVeStakerContract,
  useVeStakerContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { ethers } from "ethers";
import { getTokens } from "src/configs/Tokens";
import { compareAddress } from "src/utils/address";
import { ELP_LIST } from "src/constants/tokens";
import { useLockedAmount } from "./useQueryDao";

export const useEusdAccount = () => {
  const { address: account } = useWeb3Context();
  const eusdContract = useEusdContract();

  const { result } = useSingleCallResult(eusdContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEUSDTotalSupply = () => {
  const eusdContract = useEusdContract();

  const { result } = useSingleCallResult(eusdContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDETotalSupply = () => {
  const EDEContract = useEDEContract();

  const { result } = useSingleCallResult(EDEContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDEAccount = () => {
  const { address } = useWeb3Context();
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useVeStakerLocked = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useOldVeStakerLocked = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useGEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useOldGEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useOLDVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useGEDETotalSupply = () => {
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useOldGEDETotalSupply = () => {
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDEVeStakerTotalSupply = () => {
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalEDESupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
export const useOldEDEVeStakerTotalSupply = () => {
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalEDESupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useAllEDEVeStakerTotalSupply = () => {
  const one = useEDEVeStakerTotalSupply();
  const two = useOldEDEVeStakerTotalSupply();
  return useMemo(() => {
    if (!one) return;
    else if (one && !two) return one;
    else if (one && two) return one.plus(two);
  }, [one, two]);
};

export const useEDEStakedAccount = () => {
  const { address: account } = useWeb3Context();
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useAEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const aedeContract = useAEDEContract();
  const { result } = useSingleCallResult(aedeContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEdeYieldDistributor = () => {
  const EdeYieldDistributorContract = useEdeDistributionContract();
  const { result } = useSingleCallResult(
    EdeYieldDistributorContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEUSDYieldDistributor = () => {
  const EdeYieldDistributorContract = useEusdDistributionContract();
  const { result } = useSingleCallResult(
    EdeYieldDistributorContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
export const useEDETotalBalance = () => {
  const gedeBalance = useGEDEAccount();
  const edeBalance = useEDEAccount();
  const edeStaked = useEDEStakedAccount();
  const eusdBalance = useEusdAccount();
  const balance_aEDE = useAEDEAccount();

  return useMemo(() => {
    if (
      !gedeBalance ||
      !edeBalance ||
      !edeStaked ||
      !eusdBalance ||
      !balance_aEDE
    )
      return {
        gedeBalance,
        edeBalance,
        edeStaked,
        eusdBalance,
        balance_aEDE,
      };
  }, [balance_aEDE, edeBalance, edeStaked, eusdBalance, gedeBalance]);
};
export const useQueryEarnApr = () => {
  const gedeBalance = useGEDEAccount();
  const gedeOldBalance = useOldGEDEAccount();
  const weekRewards_ede = useEdeYieldDistributor();
  const weekRewards_eusd = useEUSDYieldDistributor();
  const totalSupply_gede = useGEDETotalSupply();
  const oldtotalSupply_gede = useOldGEDETotalSupply();

  const edePrice = useGetEdePrice();
  const edeStakedAmount = useVeStakerLocked();
  const oldedeStakedAmount = useOldVeStakerLocked();

  return useMemo(() => {
    if (
      !gedeBalance ||
      !gedeOldBalance ||
      !weekRewards_ede ||
      !weekRewards_eusd ||
      !totalSupply_gede ||
      !edePrice ||
      !edeStakedAmount ||
      !oldtotalSupply_gede ||
      !oldedeStakedAmount
    )
      return;
    let edeApr1 = BN(0);
    let edeApr2 = BN(0);
    let eusdApr1 = BN(0);
    let eusdApr2 = BN(0);
    const allgedeBalance = gedeBalance.plus(gedeOldBalance);
    const alltotalSupply_gede = totalSupply_gede.plus(oldtotalSupply_gede);
    const alledeStaked = edeStakedAmount.amount.plus(oldedeStakedAmount.amount);

    if (alltotalSupply_gede.gt(0)) {
      edeApr1 = weekRewards_ede.div(7).times(365).div(alltotalSupply_gede);
      edeApr2 = weekRewards_ede
        .div(7)
        .times(365)
        .div(alltotalSupply_gede)
        .times(4);
    }

    if (weekRewards_eusd.gt(0) && alltotalSupply_gede.gt(0)) {
      eusdApr1 = weekRewards_eusd
        .div(edePrice)
        .div(7)
        .times(365)
        .div(alltotalSupply_gede);
      eusdApr2 = weekRewards_eusd
        .div(edePrice)
        .div(7)
        .times(365)
        .div(alltotalSupply_gede)
        .times(4);
    }
    const minApr = edeApr1.plus(eusdApr1);
    const maxApr = edeApr2.plus(eusdApr2);
    const edeApr = allgedeBalance.div(alledeStaked).times(edeApr1);
    const eusdApr = allgedeBalance.div(alledeStaked).times(eusdApr1);
    const estApr = edeApr.plus(eusdApr);
    const currtimestamp = moment().valueOf();
    const currtimestampS = (currtimestamp / 1000).toFixed(0);
    const endTime = edeStakedAmount.endTime - Number(currtimestampS);

    return {
      gedeBalance: allgedeBalance,
      oldgedeBalance: gedeOldBalance,
      edeApr1,
      edeApr2,
      eusdApr1,
      eusdApr2,
      minApr,
      maxApr,
      edeApr,
      eusdApr,
      estApr,
      endTime,
      edeStaked: alledeStaked,
      newEdeStaked: edeStakedAmount.amount,
    };
  }, [
    edePrice,
    edeStakedAmount,
    gedeBalance,
    gedeOldBalance,
    oldedeStakedAmount,
    oldtotalSupply_gede,
    totalSupply_gede,
    weekRewards_ede,
    weekRewards_eusd,
  ]);
};

export const useEUSDPoolInfo = () => {
  const EusdContract = useEusdContract();
  const { result } = useSingleCallResult(EusdContract, "getEUSDPoolInfo");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return {
      totalCollateral: toFromBN(data[0], 30),
      circulatingSupply: toFromBN(data[2]),
      buyFees: BN(data[4].toString()),
      sellFees: BN(data[5].toString()),
      collateralRatio: toFromBN(data[0], 30).div(toFromBN(data[2])).times(100),
    };
  }, [result]);
};

export const useEUSDBalance = () => {
  const { address: account } = useWeb3Context();
  const EusdContract = useEusdContract();
  const { result } = useSingleCallResult(EusdContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useEDEStakeRewards = () => {
  const { address: account } = useWeb3Context();
  const EdeYieldDistributorContract = useEdeDistributionContract();
  const eusdYieldDistributorContract = useEusdDistributionContract();

  const { result: edeReward } = useSingleCallResult(
    EdeYieldDistributorContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  const { result: eusdReward } = useSingleCallResult(
    eusdYieldDistributorContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  return useMemo(() => {
    if (!edeReward || !eusdReward) return;
    const rewards_ede = toFromBN(edeReward[0]);
    const rewards_eusd = toFromBN(eusdReward[0]);
    return {
      rewards_ede,
      rewards_eusd,
    };
  }, [edeReward, eusdReward]);
};

export const useQueryInfoHelper = () => {
  const { address: account, chainID } = useWeb3Context();
  const InfoHelperContract = useInfoHelperContract();
  const data = useMemo(() => {
    return [ADDRESS[chainID].ESBT, account ? account : PLACEHOLDER_ACCOUNT];
  }, [account, chainID]);
  const { result } = useSingleCallResult(
    InfoHelperContract,
    "getBasicInfo",
    data,
    {
      gasRequired: 6000000,
    }
  );
  return useMemo(() => {
    if (!result) return;
    const userInfoArr = result[2];
    const rank = userInfoArr[10];
    return rank;
  }, [result]);
};

export const useQeuryEUSDCollateral = () => {
  const EusdContract = useEusdContract();
  const { chainID } = useWeb3Context();
  const tokens = getTokens(chainID);
  const { result } = useSingleCallResult(
    EusdContract,
    "getEUSDCollateralDetail",
    undefined,
    { gasRequired: 3000000 }
  );
  return useMemo(() => {
    if (!result) return;
    const tokenAddressList = result[0];
    const tokenPriceList = result[1];
    const tokenPoolList = result[2];
    const collateralList = [];
    for (let i = 0; i < tokenAddressList.length; i++) {
      const currTokenArr = tokens.filter((token: { address: any }) =>
        compareAddress(tokenAddressList[i], token.address)
      );
      if (currTokenArr[0]) {
        const currToken = currTokenArr[0];
        const tokenAmount = ethers.utils.formatUnits(
          tokenPriceList[i],
          currTokenArr[0].decimals
        );
        const pool = ethers.utils.formatUnits(tokenPoolList[i], 30);
        const price = Number(pool) > 0 ? Number(pool) / Number(tokenAmount) : 0;
        const amount = Number(pool) > 0 ? Number(pool) / price : 0;
        collateralList.push({ amount, price, pool, ...currToken });
      }
    }

    return collateralList;
  }, [result, tokens]);
};

export const useQueryCircLocked = () => {
  const { chainID } = useWeb3Context();
  const EDEContract = useEDEContract();
  const totalSupply_ede = useEDETotalSupply();
  const newtotalSupply_ede = useEDEVeStakerTotalSupply();
  const totalStaked_ede = useAllEDEVeStakerTotalSupply();
  const totalSupply_gede = useGEDETotalSupply();
  const ELP_1 = ELP_LIST[chainID]?.[0].rewardTracker_address;
  const EDETeam = ADDRESS[chainID]?.EDETeam;
  const EDEMarketing = ADDRESS[chainID]?.EDEMarketing;
  const EDEContractsReserve = ADDRESS[chainID]?.EDEContractsReserve;
  const LPPair = ADDRESS[chainID].LPPair;
  const Treasury = ADDRESS[chainID].Treasury;
  const TradeRebate = ADDRESS[chainID].TradeRebate;
  const EDEDAOTeam = ADDRESS[chainID].EDEDAOTeam;
  const lpStake = ADDRESS[chainID].lpStake;
  const TokenVesting_EDE = ADDRESS[chainID].TokenVesting_EDE;

  const lockedAmount = useLockedAmount();

  const { result: elp1_str } = useSingleCallResult(EDEContract, "balanceOf", [
    ELP_1,
  ]);
  const { result: edeTeam_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDETeam]
  );
  const { result: EDEMarketing_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDEMarketing]
  );
  const { result: EDEContractsReserve_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDEContractsReserve]
  );
  const { result: LPPair_str } = useSingleCallResult(EDEContract, "balanceOf", [
    LPPair,
  ]);
  const { result: Treasury_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [Treasury]
  );
  const { result: TradeRebate_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TradeRebate]
  );

  const { result: EDEDAOTeam_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDEDAOTeam]
  );
  const { result: lpStake_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [lpStake]
  );
  const { result: TokenVesting_EDE_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TokenVesting_EDE]
  );
  // 流通量 = 总发行量 - 3个多签 - EDE DAO多签钱包 - DAO treasury合约 - ELP stake 奖励合约 - 交易挖矿奖励合约 -lp 挖矿 - TokenVesting_EDE aEDE 释放

  const EdeCirculate = useMemo(() => {
    const all = toFromBN(elp1_str?.[0] ?? bigNumberify(0))
      .plus(toFromBN(edeTeam_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(EDEMarketing_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(EDEContractsReserve_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(EDEDAOTeam_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(Treasury_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(TradeRebate_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(lpStake_str?.[0] ?? bigNumberify(0)))
      .plus(toFromBN(TokenVesting_EDE_str?.[0] ?? bigNumberify(0)));
    return all;
  }, [
    EDEContractsReserve_str,
    EDEDAOTeam_str,
    EDEMarketing_str,
    TokenVesting_EDE_str,
    TradeRebate_str,
    Treasury_str,
    edeTeam_str,
    elp1_str,
    lpStake_str,
  ]);
  const circulatingSupply_common = useMemo(() => {
    if (!totalSupply_ede) return;
    const data = totalSupply_ede.minus(EdeCirculate);

    return data;
  }, [EdeCirculate, totalSupply_ede]);

  const circulatingSupply = useMemo(() => {
    if (!circulatingSupply_common) return;
    const data = circulatingSupply_common;
    return data;
  }, [circulatingSupply_common]);

  const CirculatingLp = useMemo(() => {
    if (!LPPair_str || !Treasury_str || !circulatingSupply) return;
    return toFromBN(LPPair_str?.[0] ?? bigNumberify(0)).minus(
      toFromBN(Treasury_str?.[0] ?? bigNumberify(0))
    );
  }, [LPPair_str, Treasury_str, circulatingSupply]);
  const CirculatingHolders = useMemo(() => {
    if (!LPPair_str || !Treasury_str || !lockedAmount) return;
    return circulatingSupply
      ?.minus(toFromBN(LPPair_str?.[0] ?? bigNumberify(0)))
      .minus(lockedAmount);
  }, [LPPair_str, Treasury_str, circulatingSupply, lockedAmount]);

  const CirculatingStaked = useMemo(() => {
    if (!lockedAmount) return;
    return lockedAmount;
  }, [lockedAmount]);
  const circLocked = useMemo(() => {
    if (!totalStaked_ede || !circulatingSupply) return;
    return totalStaked_ede.div(circulatingSupply);
  }, [circulatingSupply, totalStaked_ede]);

  const avgLockTime = useMemo(() => {
    if (!totalSupply_gede || !newtotalSupply_ede) return;
    return totalSupply_gede.div(newtotalSupply_ede).minus(1).div(3).times(0.5);
  }, [newtotalSupply_ede, totalSupply_gede]);
  return useMemo(() => {
    return {
      totalSupply_ede,
      totalStaked_ede,
      circulatingSupply,
      circLocked,
      avgLockTime,
      CirculatingLp,
      CirculatingHolders,
      CirculatingStaked,
    };
  }, [
    CirculatingHolders,
    CirculatingLp,
    CirculatingStaked,
    avgLockTime,
    circLocked,
    circulatingSupply,
    totalStaked_ede,
    totalSupply_ede,
  ]);
};
