import { useWeb3Context } from "./web3Context";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import {
  useEDEContract,
  useEDEStakingContract,
  useEDETContract,
  useRedeemEDETContract,
  useTreasuryContract,
} from "./useContract";
import { useEffect, useMemo, useState } from "react";
import { BN, toFromBN } from "src/utils/bn";
import { addresses as ADDRESS } from "src/configs/constants";
import { useGetLpPrice } from "./useLpPrice";
import { useSelector } from "react-redux";
import { compareAddress } from "src/utils/address";
import { PLACEHOLDER_ACCOUNT, bigNumberify } from "src/helpers/Helpers";
import { getAuctionClient } from "src/Api/common";
import { gql } from "@apollo/client";
import BigNumber from "bignumber.js";
import { useAuctionsAddress2 } from "./useQueryAuction";
import { useGetEdePrice } from "./getPrice_ede";
import { ELP_LIST } from "src/constants/tokens";

export const useEDETAmount = () => {
  const { address: account } = useWeb3Context();
  const EDETContract = useEDETContract();
  const { result } = useSingleCallResult(EDETContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useIsStart = (currentRound: any) => {
  const RedeemEDETContract = useRedeemEDETContract();

  const { result } = useSingleCallResult(RedeemEDETContract, "roundStart", [
    currentRound,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useNextOpenRedeemTime = () => {
  const RedeemEDETContract = useRedeemEDETContract();

  const { result } = useSingleCallResult(
    RedeemEDETContract,
    "nextOpenRedeemTime"
  );

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useNextroundStart = () => {
  const RedeemEDETContract = useRedeemEDETContract();

  const { result } = useSingleCallResult(RedeemEDETContract, "roundStart", [1]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};
export const useIsOpenRedeemNow = () => {
  const RedeemEDETContract = useRedeemEDETContract();

  const { result } = useSingleCallResult(RedeemEDETContract, "isOpenRedeemNow");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useEstimatedReward = (amount: any) => {
  const RedeemEDETContract = useRedeemEDETContract();

  const { result, error } = useSingleCallResult(
    RedeemEDETContract,
    "estimatedReward",
    [amount]
  );

  // console.log("error", result, error);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    // console.log("data", data);
    if (data.length == 0) return;
    return {
      estimatedReward_EUSD: toFromBN(data[0]),
      estimatedReward_EDE: toFromBN(data[1]),
      estimatedReward_ELP: toFromBN(data[2]),
      estimatedReward_LP: toFromBN(data[3] ?? bigNumberify(0)),
    };
  }, [result]);
};

export const useRewardPerDay = () => {
  const EDEStakingContract = useEDEStakingContract();

  const { result } = useSingleCallResult(
    EDEStakingContract,
    "totalRewardPerDay",
    []
  );

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDEDepositSupply = () => {
  const EDEStakingContract = useEDEStakingContract();

  const { result } = useSingleCallResult(
    EDEStakingContract,
    "totalDepositSupply",
    []
  );

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useRewards = () => {
  const { address: account } = useWeb3Context();
  const EDEStakingContract = useEDEStakingContract();

  const { result } = useSingleCallResult(EDEStakingContract, "claimable", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return {
      edetReward: toFromBN(data),
      eusdReward: toFromBN(result[2][0]),
    };
  }, [result]);
};

export const useStakeAmount = () => {
  const { address: account } = useWeb3Context();
  const EDEStakingContract = useEDEStakingContract();

  const { result } = useSingleCallResult(EDEStakingContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useLockedAmount = () => {
  const { chainID } = useWeb3Context();
  const EDEStaking_address = ADDRESS[chainID].EDEStaking;
  const EDEContract = useEDEContract();

  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    EDEStaking_address ? EDEStaking_address : undefined,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useFeeRatio = () => {
  const EDEStakingContract = useEDEStakingContract();
  const { result } = useSingleCallResult(EDEStakingContract, "feeRatio", []);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data, 4);
  }, [result]);
};

export const useCirculation_edet = () => {
  const RedeemEDETContract = useRedeemEDETContract();
  const { result } = useSingleCallResult(
    RedeemEDETContract,
    "edetCirculation",
    []
  );
  // TODO  加上拍卖数量
  // const AdditionalEDET = useAuctionPromise();
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

const useTreasuryBalance_EUSD = () => {
  const { chainID } = useWeb3Context();
  const EUSD_address = ADDRESS[chainID].EUSD;
  const treasuryContract = useTreasuryContract();
  const { result } = useSingleCallResult(treasuryContract, "balanceOf", [
    EUSD_address ? EUSD_address : undefined,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

const useTreasuryBalance_EDE_LP = () => {
  const { chainID } = useWeb3Context();
  const EDE_LP_address = ADDRESS[chainID].LPPair;
  const treasuryContract = useTreasuryContract();
  const { result } = useSingleCallResult(treasuryContract, "balanceOf", [
    EDE_LP_address ? EDE_LP_address : undefined,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

const useTreasuryBalance_ELP = () => {
  const { chainID } = useWeb3Context();
  const ELP_address = ELP_LIST[chainID]?.[0].Elp_address;
  const treasuryContract = useTreasuryContract();
  const { result } = useSingleCallResult(treasuryContract, "balanceOf", [
    ELP_address ? ELP_address : undefined,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

const useTreasuryBalance_EDE = () => {
  const { chainID } = useWeb3Context();
  const ede_address = ADDRESS[chainID].EDE;

  const treasuryContract = useTreasuryContract();
  const { result } = useSingleCallResult(treasuryContract, "balanceOf", [
    ede_address ? ede_address : undefined,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

const usePrice_EDE_LP = () => {
  const { chainID } = useWeb3Context();
  const EDE_LP_address = ADDRESS[chainID].LPPair;
  const result = useGetLpPrice(EDE_LP_address);
  return useMemo(() => {
    if (!result) return;
    return result;
  }, [result]);
};

const usePrice_ELP = () => {
  const { chainID } = useWeb3Context();
  const ELP_address = ELP_LIST[chainID]?.[0].Elp_address;
  const tokensArr = useSelector((state: any) => {
    return state.app.tokensArr || [];
  });
  const ELP_arr = tokensArr?.filter((token: any) =>
    compareAddress(token.address, ELP_address)
  );
  return useMemo(() => {
    if (ELP_arr.length < 1) return;
    return ELP_arr[0]?.price;
  }, [ELP_arr]);
};

export const useDaoInfo = () => {
  const balance_EUSD = useTreasuryBalance_EUSD();
  const balance_ELP = useTreasuryBalance_ELP();
  const balance_EDE = useTreasuryBalance_EDE();
  const price_ELP = usePrice_ELP();
  const price_EDE_LP = usePrice_EDE_LP();
  const balance_EDE_LP = useTreasuryBalance_EDE_LP();

  return useMemo(() => {
    if (!balance_EUSD || !balance_ELP || !balance_EDE || !price_ELP) return;
    return {
      balance_EUSD: balance_EUSD,
      balance_ELP: balance_ELP,
      price_ELP,
      balance_EDE: balance_EDE,
      price_EDE_LP,
      balance_EDE_LP,
    };
  }, [
    balance_EDE,
    balance_EDE_LP,
    balance_ELP,
    balance_EUSD,
    price_EDE_LP,
    price_ELP,
  ]);
};

export const useTreasuryVal = () => {
  const edePrice = useGetEdePrice();
  const daoData = useDaoInfo();
  return useMemo(() => {
    if (!daoData || !edePrice || !daoData?.balance_EDE_LP) return;
    return daoData?.balance_EUSD
      .plus(daoData?.balance_ELP.times(daoData?.price_ELP))
      .plus(daoData?.balance_EDE.times(edePrice))
      .plus(daoData?.balance_EDE_LP.times(daoData?.price_EDE_LP || 0));
  }, [daoData, edePrice]);
};

export const usePrice_EDET = () => {
  const circulation_edet = useCirculation_edet();
  const treasuryVal = useTreasuryVal();
  return useMemo(() => {
    if (!circulation_edet || !treasuryVal) return;
    return treasuryVal.div(circulation_edet);
  }, [circulation_edet, treasuryVal]);
};

function useAuctionPromise() {
  const { chainID } = useWeb3Context();
  const [auctionNum, setauctionList] = useState<any>();

  const { result: auctionAddress, loading } = useAuctionsAddress2();
  const auction_gql = useMemo(() => {
    if (auctionAddress)
      return gql(`{
      userCommitments
      (first: 5, orderBy:timeStamp,orderDirection:desc,where:{auction:"${auctionAddress.toLowerCase()}"})
      {
        id
        auction
        adder
        commitment
        timeStamp
        hash
      }
      auctions(first: 5,orderBy:startTimeStamp,orderDirection:desc) {
        id
        style
        startTimeStamp
        endTimeStamp
        payInToken
        totalTokens
        status
      }
    }`);
  }, [auctionAddress]);
  useEffect(() => {
    if (!loading && !auctionAddress) {
      setauctionList(BN(0));
    }
    if (chainID && auction_gql) {
      getAuctionClient(chainID)
        .query({ query: auction_gql })
        .then((res) => {
          const data = res.data;
          const totalAll = data?.auctions.reduce((pre: BigNumber, arr: any) => {
            if (arr.status === "Success") {
              pre = pre.plus(toFromBN(arr.totalTokens));
            }
            return pre;
          }, BN(0));
          setauctionList(totalAll);
        })
        .catch((err) => {
          console.log("graphql trades err: ", err);
        });
    }
  }, [auctionAddress, auction_gql, chainID, loading]);

  return auctionNum;
}
