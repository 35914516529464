import BNBIcon from "../../assets/images/tokens/BNB.svg";
import ArbitrumIcon from "../../assets/images/tokens/Arbitrum.svg";
import BSE_logo from "src/assets/images/BSE_logo.svg";
export type AddressEnvMap = { [chainId: number]: number };
export enum SupportedChainId {
  BSCTEST = 97,
  ARB_TEST = 421613,
  BSCMAINNET = 56,
  ARBITRUM = 42161,
  BASE_TEST = 84531,
  BASE_MAINNET = 8453,
}

export const NETWORK_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://goerli.arbiscan.io",
  [SupportedChainId.BSCTEST]: "https://testnet.bscscan.com",

  [SupportedChainId.ARBITRUM]: "https://arbiscan.io",
  [SupportedChainId.BSCMAINNET]: "https://bscscan.com",
  [SupportedChainId.BASE_TEST]: "https://goerli.basescan.org",
  [SupportedChainId.BASE_MAINNET]: "https://basescan.org",
};

export const RPC_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]:
    "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
  [SupportedChainId.BSCTEST]: "https://data-seed-prebsc-1-s1.binance.org:8545",
  [SupportedChainId.ARBITRUM]:
    "https://endpoints.omniatech.io/v1/arbitrum/one/public",
  [SupportedChainId.BSCMAINNET]:
    "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
  [SupportedChainId.BASE_TEST]: "https://base-goerli.public.blastapi.io",
  [SupportedChainId.BASE_MAINNET]: "https://developer-access-mainnet.base.org",
};

export const CHAINNAME_LIST = {
  [SupportedChainId.BSCTEST]: {
    chainName: "BSC Testnet",
    nativeCurrency: "BNB",
    icon: BNBIcon,
  },
  [SupportedChainId.ARB_TEST]: {
    chainName: "Arbitrum Testnet",
    nativeCurrency: "ETH",
    icon: ArbitrumIcon,
  },
  [SupportedChainId.BSCMAINNET]: {
    chainName: "BNB Smart Chain",
    nativeCurrency: "BNB",
    icon: BNBIcon,
  },

  [SupportedChainId.ARBITRUM]: {
    chainName: "Arbitrum",
    nativeCurrency: "ETH",
    icon: ArbitrumIcon,
  },
  [SupportedChainId.BASE_TEST]: {
    chainName: "baseTest",
    nativeCurrency: "ETH",
    icon: BSE_logo,
  },
  [SupportedChainId.BASE_MAINNET]: {
    chainName: "Base",
    nativeCurrency: "ETH",
    icon: BSE_logo,
  },
};
